import { faImage } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import log from "loglevel"
import React from "react"

export interface GridViewContainerProps extends React.HTMLAttributes<HTMLElement> {
    adminMessage: boolean | undefined
}

export interface GridItemProps extends React.HTMLAttributes<HTMLElement> {
    src?: string,
    description?: string,
    maxWidth?: string,
    callback?: (src: string) => void
}

export const GridViewContainer:React.FC<React.PropsWithChildren<GridViewContainerProps>> = ({adminMessage, ...props}) => {
    return(
        <>
            <div className={`display-wrapper wrap-auto flex-wrap ${adminMessage ? 'justify-end' : 'justify-start'}`}>
                {props.children}
            </div>
        </>
    )
}

export const GridItem:React.FC<React.PropsWithChildren<{maxWidth?: string} & React.HTMLAttributes<HTMLElement>>> = ({...props}) => {
    return (
        <div 
            className={classNames(
                "m-0 mb-2 m-sm-3",
                props.className
            )} 
            style={{'maxWidth': props.maxWidth}}
        >
            {props.children}
        </div>
    )
}

const withGridItem:React.FC<React.PropsWithChildren> = ({...props}) => {
    return (
        <div className="m-0 mb-2 m-sm-3">
            {props.children}
        </div>
    )
}

export const GridItemImage: React.FC<React.PropsWithChildren<GridItemProps>> = ({src, description, callback, ...props}) => {
    log.debug("grid item image => ", {src, description})
    return (
        <GridItem
            maxWidth={props.maxWidth}>
            {src?
                <img 
                    //className="img-thumbnail cursor-pointer d-block" 
                    className={classNames(
                        'img-thumbnail d-block text-center mx-auto',
                        {'cursor-pointer': callback},
                        props.className
                    )}
                    style={props.style} 
                    src={src} 
                    onClick={ ()=> callback && callback(src)}
                />:
                <FontAwesomeIcon className="img-md p-4" icon={faImage} color={"white"} size={"1x"}/>
            }
            {/* <div
                onClick={ ()=> callback && callback(src)}
                style={{'background':`url(${src})`,
                        'backgroundRepeat':'no-repeat',
                        'backgroundSize': 'cover',
                        'backgroundPosition': 'center center',
                        ...props.style
                }}
                className={classNames(
                    'text-center mx-auto',
                    {'cursor-pointer': callback},
                    props.className
                )}
            ></div> */}
            {description && <span className="ellipsis-one">{description}</span>}
            {props.children}
        </GridItem>
    )
}
