import {motion, Variants} from 'framer-motion'
import { initial } from 'lodash'
import { FC, PropsWithChildren } from 'react'

export type Props = PropsWithChildren<{framerKey?: any}>

const pageAnimationConfig: Variants = {
    initial: {
        // left: -window.innerWidth, 
        // zIndex: -10,
        // position: 'relative',
        position: 'relative',
        height: '0%',
        width: '0%',
        opacity: 0,
    },
    animate: {
        // left: 0, 
        // zIndex: 0,
        height: '100%',
        width: '100%',
        position: 'relative',
        opacity: 1,
    },
    exit: {
        // left: window.innerWidth, 
        // zIndex: -10,
        opacity: 0,
        position: 'relative',
        height: '0%',
        width: '0%',
    }
}

// initial={{
//     left: -window.innerWidth, 
//     //zIndex: -10,
//     position: 'relative',
// }}
// animate={{
//     left: 0, 
//     //zIndex: 0,
//     height: '100%',
//     width: '100%',
//     position: 'relative'
// }}
// exit={{
//     left: -window.innerWidth, 
//     //zIndex: -10,
//     position: 'relative',
    
// }}

export const MOTION_VARIANTS: Variants = {
    initial: ({ direction }: { direction: "forward" | "backward" }) => ({
        //x: direction === "backward" ? "-100%" : "100%",
        x: '-100%',
        position: 'relative',
        transition: {
            type: "spring",
            duration: 1.2,
            delay: 0
        }
    }),
    in: {
        x: 0,
        height: '100%',
        width: '100%',
        position: 'relative',
        transition: {
            type: "spring",
            duration: 1.2,
            delay: 0
        }
    },
    out: ({ direction }: { direction: "forward" | "backward" }) => ({
        //x: direction === "backward" ? "100%" : "-100%",
        x: '-100%',
        position: 'relative',
        transition: {
            type: "spring",
            duration: 1.2,
            delay: 0
        }
    })
};

const AnimatedPage: FC<Props> = (props) => {
    return(
        <motion.div
            // key={props.framerKey}
            // variants={pageAnimationConfig}
            // initial="initial"
            // animate="animate"
            // exit="exit"
            // transition={{
            //     duration: 1
            // }}
            custom={{ direction: "forward" }}
            initial="initial"
            animate="in"
            exit="out"
            variants={MOTION_VARIANTS}
        >
            <div className="main-panel">
                <div className="content-wrapper">
                    {props.children}
                </div>
                {/* <Footer /> */}
            </div>
        </motion.div>
    )
}
export default AnimatedPage