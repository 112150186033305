import { useTranslation } from "react-i18next"
import { Card } from "../Card/Card"
import { Col, Row, Button, DropdownButton, Dropdown } from "react-bootstrap"
import { useState, useRef, useMemo } from "react"
import { PendingRegistrationInfoFull } from "../../services/network"
import { ApiClient } from "../../services/ApiClient"
import { SecondaryCard } from "../SecondaryCard/SecondaryCard"
import { yupTeamUnlockValidationSchema } from "../../utils/form-validation-schemas"
import  {TeamUnlockForm} from "../../forms/TeamsUnlockForm/TeamUnlockForm"
import { RegistrationResponse } from "../../services/network"
import log from 'loglevel';
import 'react-toastify/dist/ReactToastify.css'
import { generateToast } from "../../utils/ToastGenerator"
import { useNavigate } from "react-router-dom"
import { TEAM_UNLOCK } from "../../routing/routes"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FullScreenFileModal } from "../FilePreviewViewer/FilePreviewViewer"
import { CarouselItem } from "../Carousel/Carousel"
import { saveAs } from 'file-saver'

interface CardRequestDetailProps {
    requestFull: PendingRegistrationInfoFull
}

const CardRequestDetail = ({ requestFull } : CardRequestDetailProps) => {
    const {t} = useTranslation()
    const [showButtonMotivation, setShowButtonMotivation] = useState<boolean>(false)
    const [showTextMotivation, setShowTextMotivation] = useState<boolean>(false)
    const [motivation, setMotivation] = useState<string>(t('team_unlock_full_page:motivation'))
    const [showFullScreenPreview, setShowFullScreenPreview] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const carouselItems: CarouselItem[] = useMemo(() => {
        log.debug("in useMemo message")
        return [
            {
                title: t('team_unlock_full_page:identity_card'),
                src: requestFull.managerIdentityCardUrl ?? 'URL not available',
                mimeType: 'MimeType not available'
            },
            {
                title: t('team_unlock_full_page:signed_authorization'),
                src: requestFull.teamSignedAuthorizationUrl ?? 'URL not available',
                mimeType: 'MimeType not available'
            }
        ]
    }, [requestFull])
    
    const formRef = useRef<any>()
    const navigation = useNavigate()
    
    const submitForm = () => formRef.current && formRef.current.handleSubmit()

    const processPendingNotification = async (body: RegistrationResponse) => {
        log.debug('TeamUnlock => processPendingNotification => body =>', body);

        if (requestFull.requestId) {
            try{
                let result = await ApiClient.adminApi().processPendingRegistration(requestFull.requestId, body)
                log.debug('TeamUnlock => processPendingNotification => ', result.status)
                generateToast('success', t('team_unlock_full_page:successfully_processed'))
                setTimeout(() => navigation(TEAM_UNLOCK), 3000)
            }
            catch(e) {
                log.debug('TeamUnlock => processPendingNotification => error => ', e)
                generateToast('error', e)
                setTimeout(() => {navigation(TEAM_UNLOCK)}, 3000)
            }
        }
        else{
            log.debug("No requestID")
        }
    }

    const showAppropriateThumbnail = (fileUrl: string, carouselIndex: number) => {
        const splittedUrl = fileUrl.split('.')
        const lastPart = splittedUrl[splittedUrl.length-1]
        const handleClick = (index: number) => {
            log.debug('Selected carouselItems => ', carouselItems[index])
            setSelectedIndex(index)
            setShowFullScreenPreview(current => !current)
        }
        switch (lastPart) {
            case 'pdf':
                return <div onClick={() => handleClick(carouselIndex)}><FontAwesomeIcon icon={faFilePdf} size={"6x"} color="#b4b4b4"/></div>
            default:
                return <div onClick={() => handleClick(carouselIndex)}><img className={classNames("img-thumbnail img-xxxl")} src={fileUrl}/></div>
        }
    }

    const downloadWhenClicked = (item: CarouselItem) => {
        const fileName = `${requestFull.teamName}-${requestFull.managerName}-${requestFull.managerSurname}-${item.title}.${item.src.split('.').pop()}`.replaceAll(' ', '')
        log.debug('Trying to download file => ', fileName)
        saveAs(item.src, fileName)
    }

    return(
        <>
            {showFullScreenPreview && FullScreenFileModal(showFullScreenPreview, setShowFullScreenPreview, undefined, undefined, carouselItems, selectedIndex, downloadWhenClicked)}

            <Card>
                <Row>
                    <h1 className="my-1">{requestFull.teamName}</h1>
                </Row>
                <Row className="specs-container">
                    <p>{requestFull.managerName}</p>
                    <p>{requestFull.managerSurname}</p>
                    <p>{requestFull.managerEmail}</p>
                </Row>
                <Row>
                    <Col >
                        <SecondaryCard
                            title={t('team_unlock_full_page:identity_card')}
                            className="display-wrapper wrap-row-center-center">
                                {showAppropriateThumbnail(requestFull.managerIdentityCardUrl ?? '', 0)}
                        </SecondaryCard>
                    </Col>
                        <SecondaryCard
                            title={t('team_unlock_full_page:signed_authorization')}
                            className="display-wrapper wrap-row-center-center">
                                {showAppropriateThumbnail(requestFull.teamSignedAuthorizationUrl ?? '', 1)}
                        </SecondaryCard>
                </Row>
                <Row>
                    <Col>
                        <Button
                            className="btn-rounded btn-md text-uppercase btn-secondary"
                            type="button"
                            onClick={()=>{
                                setShowButtonMotivation(state => !state);
                                setShowTextMotivation(false);
                                setMotivation(t('team_unlock_full_page:motivation'))}}>
                            {t('team_unlock_full_page:missing_link')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="btn-rounded btn-md text-uppercase"
                            type="submit"
                            onClick={()=>processPendingNotification({accepted : true,
                                                    reasonType : "accepted",
                                                    reasonDescription : "accepted"}
                                                    )}>
                            {t('team_unlock_full_page:link')}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <p></p>
                </Row>

                <div>
                    {
                        showButtonMotivation &&
                        <DropdownButton title={motivation} size="lg" >
                            <Dropdown.Item
                                onClick={()=>{
                                    setShowTextMotivation(true);
                                    setMotivation(t('team_unlock_full_page:dropdown_menu.field1'))}}>
                                        {t('team_unlock_full_page:dropdown_menu.field1')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={()=>{
                                    setShowTextMotivation(true);
                                    setMotivation(t('team_unlock_full_page:dropdown_menu.field2'))}}>
                                        {t('team_unlock_full_page:dropdown_menu.field2')}
                            </Dropdown.Item>
                            <Dropdown.Item 
                                onClick={()=>{
                                    setShowTextMotivation(true);
                                    setMotivation(t('team_unlock_full_page:dropdown_menu.field3'))}}>
                                        {t('team_unlock_full_page:dropdown_menu.field3')}
                            </Dropdown.Item>
                            <Dropdown.Item 
                                 onClick={()=>{
                                    setShowTextMotivation(true);
                                    setMotivation(t('team_unlock_full_page:dropdown_menu.field4'))}}>
                                        {t('team_unlock_full_page:dropdown_menu.field4')}
                            </Dropdown.Item>
                        </DropdownButton>
                    }
                </div>
                <div>
                    {
                    showTextMotivation &&
                    <div>
                        <p></p>
                        <Row>
                            <Col>
                                <TeamUnlockForm
                                    initialValues={{
                                        accepted : false,
                                        reasonType : motivation,
                                        reasonDescription : ""
                                    }}
                                    validationSchema={yupTeamUnlockValidationSchema}
                                    onSubmitCallback={processPendingNotification}
                                    isSubmitting={false}
                                    formRef={formRef}/>
                            </Col>
                        </Row>
                        <p></p>
                        <Row>
                            <Col></Col>
                            <Col className="text-end">
                                <Button 
                                    variant="primary"
                                    className="btn-rounded btn-md w-100 w-sm-auto text-uppercase" 
                                    type="submit" 
                                    onClick={submitForm}>
                                    {t('common:send')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>
            </Card>
        </>
    )
}

export { CardRequestDetail }