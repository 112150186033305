import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Card } from "../../components/Card/Card"
import { Button} from "react-bootstrap"
import { ManagerAdminThread, ManagerAdminMessage } from "../../services/network"
import { useEffect, useState, useRef } from "react"
import { ApiClient } from "../../services/ApiClient"
import log from "loglevel"
import { CardCommunicationDetail } from "../../components/CardCommunicationDetail/CardCommunicationDetail"
import { NewReplyMessageRequest, TeamCommunicationReplyForm } from "../../forms/TeamCommunicationReplyForm/TeamCommunicationReplyForm"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify"
import { throwNewError } from "../../utils/ErrorDispatcher"
import { generateToast } from "../../utils/ToastGenerator"
import { yupTeamCommunicationReplyValidationSchema } from "../../utils/form-validation-schemas"

const TeamCommunicationDetail = () => {
    
    const navigation = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    const [thread, setThread] = useState<ManagerAdminThread>({})
    const [loading, setLoading] = useState(true)
    const [showReplyForm, setShowReplyForm] = useState<boolean>(false)
    let { threadId } = useParams();
    const formRef = useRef<any>()

    const postMessage = async (newMessage: NewReplyMessageRequest) => {
        try{
            const res = await ApiClient.adminApi().sendManagerAdminMessage(
                newMessage.body, 
                undefined, 
                undefined, 
                undefined, 
                newMessage.threadId, 
                newMessage.media
                )
            log.debug('TeamCommunication => sendManagerAdminMessage =>', res.status)
            setShowReplyForm(false)
            res.data.error ? throwNewError(res.data.error) : threadId && getThread(threadId)
        }
        catch(e) {
            log.debug("TeamCommunication => postMessage => error => ")
            generateToast('error', e)
        }
    }

    const handleButtonClick = () => {
        if (showReplyForm) {
            formRef.current && formRef.current.handleSubmit()
        } else {
            setShowReplyForm(true);
        }
    }
 
    const getThread = async (threadId : string) => {
        try{
            let res = await ApiClient.adminApi().getManagerAdminThread(threadId)
            log.debug('TeamManagement => getThread =>', res.data)
            setThread(res.data.data ?? throwNewError(res.data.error))
        } catch(e) {
            log.debug('TeamCommunication => getThread => error => ', e)
            generateToast('error', e)
        }
        setLoading(false)
    }

    useEffect(() => {
        threadId && getThread(threadId)
    }, [])

    return (
        <AnimatedPage>
            <ToastContainer />
            <Header title={t('communication_team')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                {loading && <h3 className="my-2">{t('team_communication_page:list.loading')}</h3>}
                {!loading &&
                <Card titleClassName="m-0" 
                      bodyClassName="pb-4" 
                      title={thread.title}>
                    
                    {thread.messages && thread.messages.map((message) => <CardCommunicationDetail managerName={`${thread.managerName} ${thread.managerSurname}`} message={message}/>)}
                    
                    {showReplyForm && threadId && 
                    <div>
                        <TeamCommunicationReplyForm
                            threadId={threadId}
                            validationSchema={yupTeamCommunicationReplyValidationSchema}
                            onSubmitCallback={postMessage}
                            formRef={formRef}/>
                    </div>}
                    <div className="text-end">
                        <Button variant="primary" 
                                className="btn-md btn-rounded mt-3 text-uppercase"
                                onClick={handleButtonClick}>
                                    {t('team_communication_page:buttons.answer')}
                        </Button>
                    </div>
                </Card>
                }
            </VerticalScrollableContent>
        </AnimatedPage>
    )
}

export { TeamCommunicationDetail }