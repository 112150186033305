import {Form} from 'react-bootstrap'
import {
    Formik,
} from 'formik';
import log from 'loglevel';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCredentials } from '../../services/network';

export type LoginFormProps ={
    initialValues: AuthCredentials,
    validationSchema: any,
    onSubmitCallback: Function,
    isSubmitting: any,
    formRef: any
}

const LoginForm = ({initialValues, validationSchema, onSubmitCallback, isSubmitting, formRef}: LoginFormProps) => {
    const { t } = useTranslation(['yup'])
    log.debug("initialValues => ", initialValues)
    return (
        <Formik
                enableReinitialize={true}
                validationSchema={validationSchema} 
                onSubmit={values => {onSubmitCallback(values)}}
                initialValues={initialValues}
                innerRef = {formRef}
        >
        {formik => {
            const { handleSubmit, values, touched, isValid, errors, getFieldProps, dirty } = formik
            // {log.debug("form status => ", [touched, errors])}
            return (
                <Form onSubmit={handleSubmit} noValidate>
                    <Form.Group controlId="email">
                        <Form.Label className="col-form-label-sm">
                            {t('login_page:form.email_label')}
                        </Form.Label>
                        <Form.Control 
                            type="email" 
                            {...getFieldProps("email")} 
                            isInvalid={!!touched.email && !!errors.email}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.email ? <div>{t(`yup:${errors.email}`)}</div> : null}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Label className="col-form-label-sm">
                            {t('login_page:form.password_label')}
                        </Form.Label>
                        <Form.Control 
                            type="password" 
                            {...getFieldProps("password")} 
                            isInvalid={!!touched.password && !!errors.password}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.password ? <div>{t(`yup:${errors.password}`)}</div> : null}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form> 
            )
        }}
      </Formik>
    )
}

export default LoginForm