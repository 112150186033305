import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

export type Props = {
    title: string,
    icon: any,
    destination: string
}

export const DashboardCard: FC<Props> = ({title, icon, destination}) => {
    const navigation = useNavigate()

    const goTo = () => {
        navigation(destination)
    }

    return <>
        <Col sm={6} md={4} className={'grid-margin'} onClick={goTo}>
            <div className="card card-extended-height cursor-pointer">
                <h5 className='card-title card-title-shadow text-center font-weight-bold'>{title}</h5>
                <div className="card-body display-wrapper wrap-column-center">
                    <ReactSVG
                        src={icon}
                        className="svg-thumbnail"
                    />
                </div>
            </div>
        </Col>
    </>
}