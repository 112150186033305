import './App.scss'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Login from './pages/Login/Login'
import {TeamManagement} from './pages/TeamManagement/TeamManagement'
import {TeamUnlock} from './pages/TeamUnlock/TeamUnlock'
import {TeamCommunication} from './pages/TeamCommunication/TeamCommunication'
import Dashboard from './pages/Dashboard/Dashboard'
import PrivateRouter from './components/PrivateRouter/PrivateRouter'
import { DASHBOARD_PATH, LOGIN_PATH, TEAM_COMMUNICATION, TEAM_COMMUNICATION_DETAIL, TEAM_COMMUNICATION_NEW, TEAM_MANAGEMENT, TEAM_MANAGEMENT_DETAIL, TEAM_UNLOCK, TEAM_UNLOCK_DETAIL } from './routing/routes'
import {TeamCommunicationDetail} from './pages/TeamCommunicationDetail/TeamCommunicationDetail'
import {TeamCommunicationNew} from './pages/TeamCommunicationNew/TeamCommunicationNew'
import {TeamUnlockDetail} from './pages/TeamUnlockDetail/TeamUnlockDetail'
import {TeamManagementDetail} from './pages/TeamManagementDetail/TeamManagementDetail'
import { ToastContainer } from 'react-toastify'
import './strings/i18n-strings'
import MainPage from './pages/MainPage/MainPage'
import NoMatch from './pages/NoMatch/NoMatch'
import log from 'loglevel';

function App() {
  const location = useLocation()

  log.debug("Location => ", location )
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>          
          <Route path='/' element={<PrivateRouter child={<MainPage />}/>}>          
            <Route index element={<></>}/>
            <Route path="*" element={<NoMatch />}/>
          </Route>
          <Route path={DASHBOARD_PATH} element={<PrivateRouter child={<MainPage />}/>}>                    
            <Route index element={<Dashboard />}/>
            {/* management routes */}
            <Route path={TEAM_MANAGEMENT} element={<TeamManagement />}/>
            <Route path={TEAM_MANAGEMENT_DETAIL} element={<TeamManagementDetail />}/>
            {/* unlock routes */}
            <Route path={TEAM_UNLOCK} element={<TeamUnlock />}/>
            <Route path={TEAM_UNLOCK_DETAIL} element={<TeamUnlockDetail/>}/>
            {/* communication routes */}
            <Route path={TEAM_COMMUNICATION} element={<TeamCommunication/>}/>
            <Route path={TEAM_COMMUNICATION_DETAIL} element={<TeamCommunicationDetail/>}/>
            <Route path={TEAM_COMMUNICATION_NEW} element={<TeamCommunicationNew/>}/>
          </Route>
          <Route path={LOGIN_PATH} element={<Login/>}/>          
        </Routes>

        <ToastContainer />
      </AnimatePresence>
    </>
  );
}

export default App;
