import { useTranslation } from "react-i18next"
import { Card } from "../Card/Card"
import { Table } from "react-bootstrap"
import { PendingRegistrationInfo } from "../../services/network"
import moment from "moment"
import TeamLogo from "../LogoPlaceholder/TeamLogo"



interface TableUnlockSummaryProps {
    requestList: PendingRegistrationInfo[], 
    onReqSelected: Function
}

const TableUnlockSummary = ({requestList, onReqSelected}: TableUnlockSummaryProps) => {
    const {t} = useTranslation()
    return(
        <>
            <Card>
                <div>
                    <Table responsive hover className={'table-communications text-left'}>
                        <thead>
                            <tr>
                                <th className="text-center">{t('team_unlock_page:request_list.request')}</th>
                                <th className="text-center">{t('team_unlock_page:request_list.team')}</th>
                                <th className="text-center">{t('team_unlock_page:request_list.date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestList.map(req => {
                                const rowSelected = () => onReqSelected(req.requestId)
                                return  <tr className='team-row' onClick={rowSelected} key={req.requestId} >
                                            <td className="text-center">{req.status}</td>
                                            <td className="text-center">{<TeamLogo url={req.teamLogoUrl}/>}{" "}{req.teamName ?? 'no_name'}</td>                                         
                                            <td className="text-center">{req.requestDate ? moment(req.requestDate).format('LL') : '-'}</td>
                                        </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
            </Card>
        </>
    )
}

export { TableUnlockSummary }