import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/reduxStore';
import { logout } from '../../redux/reducers/auth.slice';

export const Navbar = () => {
    const reduxDispatch = useDispatch<AppDispatch>()
    const toggleOffcanvas = () =>  {
        document.querySelector('.sidebar-offcanvas')?.classList.toggle('active');
    }


    const logoutUser = () => reduxDispatch(logout())

    return(
        <>
            <nav className="navbar d-lg-none p-0 fixed-top d-flex flex-row">
                {/* <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo-mini" to="/">
                        <img src={require('../assets/images/logo.png')} alt="logo" />
                    </Link>
                </div> */}
                <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                    <button className="navbar-toggler align-self-center navbar-toggler-right" type="button" 
                        onClick={toggleOffcanvas}>
                        <span className="mdi mdi-menu"></span>
                    </button>
                    <ul className="navbar-nav w-100">
                        <li className="nav-item w-100 d-flex align-items-center justify-content-center">
                            <div className="navbar-brand-wrapper d-flex align-items-center justify-content-center">
                                <Link className="navbar-brand brand-logo-mini" to="/">
                                    <img src={require('../../assets/images/logo.png')} alt="logo" />
                                </Link>
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-nav navbar-nav-right">
                        <Dropdown as="li" className="nav-item text-right">
                            <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                                <div className="navbar-profile">
                                    <p className="mb-0 d-none navbar-profile-name">
                                        Admin
                                    </p>
                                    <i className="mdi mdi-menu-down d-none"></i>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                                <h6 className="p-3 mb-0">
                                    <Trans i18nKey={'user_profile:profile'}/>
                                </h6>
                                <Dropdown.Divider />
                                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                                    <div className="preview-item-content">
                                        <p className="preview-subject mb-1">
                                            <Trans i18nKey={'user_profile:change_password'}/>
                                        </p>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}  className="preview-item">
                                    <div className="preview-item-content">
                                        <p className="preview-subject mb-1" onClick={logoutUser}><Trans i18nKey={'user_profile:logout'}>Log Out</Trans></p>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                </div>
            </nav>
        </>
    )
}