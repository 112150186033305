import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import {SearchBar} from "../../components/SearchBar/SearchBar"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useLocation, useNavigate } from "react-router-dom"
import { TEAM_COMMUNICATION, TEAM_COMMUNICATION_NEW } from "../../routing/routes"
import { Button, Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react"
import log from "loglevel"
import { ManagerAdminThreadSummary, PaginationInfo } from "../../services/network"
import { ApiClient } from "../../services/ApiClient"
import { TableCommunicationSummary } from "../../components/TableCommunicationSummary/TableCommunicationSummary"
import classNames from "classnames"
import { generateToast } from "../../utils/ToastGenerator"
import { throwNewError } from "../../utils/ErrorDispatcher"
import { PaginationBar } from "../../components/PaginationBar/PaginationBar"

const TeamCommunication = () => {
    
    const navigation = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [communicationsList, setCommunicationsList] = useState<ManagerAdminThreadSummary[]>([])
    const [queryString, setQueryString] = useState<string | undefined>(undefined)
    const [totalPageCount, setTotalPageCount] = useState<PaginationInfo>({total_pages: 0})
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [threadsToDelete, setThreadsToDelete] = useState<number[]>([])

    const onBackClick = () => navigation(-1)
    const newCommunication = () => navigation(`${TEAM_COMMUNICATION_NEW}`)
    const navigateToCommunication = (threadId: string) => navigation(`${TEAM_COMMUNICATION}/${threadId}`, {replace: false})

    const onQueryUpdate = (queryString: string) => {
        log.debug('in onQueryUpdate queryString => ', queryString)
        setCurrentPageIndex(0)        
        setQueryString(queryString)
    }

    const deleteThreads = async () => {
        try{
            log.debug('TeamManagement => deleteThreads => threads => ', threadsToDelete)
            const res = await ApiClient.adminApi().deleteManagerAdminThread(threadsToDelete)
            log.debug('TeamManagement => deleteThreads => ', res.data)
            generateToast('success', t('team_communication_page:delete_thread_event.success'))
            getThreads(queryString, currentPageIndex)
        } catch(e) {
            log.debug('TeamCommunication => deleteThreads => error => ', e)
            generateToast('error', `${t('team_communication_page:delete_thread_event.error')}${e}`)
        }
        setThreadsToDelete([])
    }

    const getThreads = async (queryString?: string, page?: number) => {
        try{
            const res = await ApiClient.adminApi().getManagerAdminThreads(queryString, (page ?? 0) + 1)
            log.debug('TeamManagement => getThreads => ', res.data)
            setCommunicationsList(res.data.data ?? throwNewError(res.data.error))
            setTotalPageCount(res.data.pagination ?? {total_pages: 1})
        } catch(e) {
            log.debug('TeamCommunication => getThreads => error => ', e)
            generateToast('error', e)
        }
        setLoading(false)
    }

    useEffect(() => {
        log.debug('in useEffect queryString => ', queryString)
        getThreads(queryString, currentPageIndex)
    },[queryString, currentPageIndex])

    return (
        <AnimatedPage>
            <Header title={t('communication_teams')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                <Row >
                    <Col>
                        <Button
                            className="btn-rounded btn-md text-uppercase"
                            type="submit"
                            onClick={newCommunication}>
                            {t('team_communication_page:buttons.new_communication')}
                        </Button>
                    </Col>
                    {threadsToDelete.length !== 0 && 
                    <Col>
                        <Button onClick={deleteThreads}
                            variant={"primary"}
                            className={classNames("btn-rounded w-100 w-sm-auto btn-md text-uppercase")}> 
                            {t("common:delete")}
                        </Button>
                    </Col>}
                </Row>
                <p></p>
                <Row className="text-end mb-4 display-wrapper wrap-center">
                    <SearchBar className={"wrap-auto"} value={queryString ?? ''} onQueryChange={onQueryUpdate}/>
                </Row>
                {loading && <h3 className="my-2">{t('team_communication_page:list.loading')}</h3>}
                {!loading &&
                    communicationsList?.length === 0 
                    ? <h3 className="my-2">{t('team_communication_page:list.not_found')}</h3>
                    : <>
                    <TableCommunicationSummary 
                            communicationsList={communicationsList}
                            onCommunicationSelected={navigateToCommunication}
                            onThreadChecked = {(threads) => {
                                log.debug('thread checked => ', threads)
                                setThreadsToDelete(threads)
                            }}/>
                    <PaginationBar 
                        totalPageCount={totalPageCount.total_pages ?? 1} 
                        currentPageIndex={currentPageIndex} 
                        setCurrentPageIndex={setCurrentPageIndex} 
                        />
                    </>
                }
            </VerticalScrollableContent>
        </AnimatedPage>
    )
}

export { TeamCommunication }