import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useNavigate } from "react-router-dom"
import { TEAM_UNLOCK } from "../../routing/routes";
import { useEffect, useState } from "react"
import { PaginationInfo, PendingRegistrationInfo } from "../../services/network"
import { ApiClient } from "../../services/ApiClient"
import { TableUnlockSummary } from "../../components/TableUnlockSummary/TableUnlockSummary"
import log from "loglevel"
import 'react-toastify/dist/ReactToastify.css'
import { throwNewError } from "../../utils/ErrorDispatcher"
import { generateToast } from "../../utils/ToastGenerator"
import { Row } from "react-bootstrap"
import { SearchBar } from "../../components/SearchBar/SearchBar"
import { PaginationBar } from "../../components/PaginationBar/PaginationBar"


const TeamUnlock = () => {
    
    const navigation = useNavigate()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    const [requestList, setRequestList] = useState<PendingRegistrationInfo[]>([])

    const [queryString, setQueryString] = useState<string | undefined>(undefined)
    const [totalPageCount, setTotalPageCount] = useState<PaginationInfo>({total_pages: 0})
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [loading, setLoading] = useState(true)
    
    const navigateToReq = (requestId: string) => navigation(`${TEAM_UNLOCK}/${requestId}`, {replace: false})

    const onQueryUpdate = (queryString: string) => {
        log.debug('in onQueryUpdate queryString => ', queryString)
        setCurrentPageIndex(0)        
        setQueryString(queryString)
    }

    const getNotifications = async (queryString?: string, page?: number) => {
        try {
            let res = await ApiClient.adminApi().getPendingRegistrations(queryString, (page ?? 0) + 1)
            log.debug('TeamManagement => getNotifications =>', res.data)
            setRequestList(res.data.data ?? throwNewError(res.data.error))
            setTotalPageCount(res.data.pagination ?? {total_pages: 1})
        } catch (e) {
            log.debug('TeamManagement => getNotifications => error => ', e)
            generateToast('error', e)
        }
        setLoading(false)
    }

    useEffect(() => {
        getNotifications(queryString, currentPageIndex)
    }, [queryString, currentPageIndex])

    return <>
        <AnimatedPage>
            <Header title={t('unlock_teams')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                <Row className="text-end mb-4 display-wrapper wrap-center">
                    <SearchBar className={"wrap-auto"} value={queryString ?? ''} onQueryChange={onQueryUpdate}/>
                </Row>
                {loading && <h3 className="my-2">{t('team_unlock_page:request_list.loading')}</h3>}
                {!loading && (requestList.length === 0
                ? <h3 className="my-2">{t('team_unlock_page:request_list.no_request_found')}</h3>
                : <>
                    <TableUnlockSummary 
                        requestList = {requestList}
                        onReqSelected = {navigateToReq}
                        />
                    <PaginationBar 
                        totalPageCount={totalPageCount.total_pages ?? 1} 
                        currentPageIndex={currentPageIndex} 
                        setCurrentPageIndex={setCurrentPageIndex} 
                        />
                </>
                )}
            
            </VerticalScrollableContent>
        </AnimatedPage>
    </>
}

export {TeamUnlock}

