import log from "loglevel";

const getTokens = (): AuthTokens => JSON.parse(localStorage.getItem("tokens") ?? "") ?? {serverToken: "", refreshToken: ""}

const setTokens = (tokens: AuthTokens) => localStorage.setItem("tokens", JSON.stringify(tokens))

const removeTokens = (): void => {
    localStorage.removeItem("tokens")
    log.debug("[AuthProvider] Removed all tokens!")
}

const getRefreshToken = (): string => getTokens().refreshToken

const getServerToken = (): string => getTokens().serverToken

const updateServerToken = (serverToken: string): void => {
    const tokens = getTokens()
    tokens.serverToken = serverToken
    setTokens(tokens)
    log.debug("[AuthProvider] Updated server token to => ", getTokens().serverToken)
}

const isAuthenticated = (): boolean => {
    const tokens = getTokens()
    return tokens.serverToken !== "" && tokens.refreshToken !== ""
}

export interface AuthTokens {
    serverToken: string
    refreshToken: string
}

export const AuthProvider = {
    setTokens,
    getRefreshToken,
    getServerToken,
    updateServerToken,
    removeTokens,
    cancelTokens: () => removeTokens(),
    isAuthenticated
}