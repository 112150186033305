
import { Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

interface SecondaryCardProps extends React.HTMLAttributes<HTMLElement>{
    title: string,
    className?: string
}

const SecondaryCard = ({title, className, ...props}: SecondaryCardProps) => {
    return <>
        <Col className={'grid-margin'}>
            <div className="card secondary">
                <h5 className='card-title card-title-shadow'>{title}</h5>
                <div className={`card-body ${className}`}>
                    {props.children}
                </div>
            </div>
        </Col>
    </>
}

export { SecondaryCard }