import { AdminApi, AuthApi, Configuration } from "./network"
import { AuthProvider } from './AuthProvider'
import { throwNewError } from "../utils/ErrorDispatcher"
import { AxiosResponse } from "axios"
import log from "loglevel"

const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'https://dev.api.teamkeys.it'
const ROOT_URL = process.env.REACT_APP_ROOT_URL ?? 'managing/admin'
const AUTH_URL = process.env.REACT_APP_AUTH_URL ?? 'auth'
const API_VERSION = process.env.REACT_APP_API_VERSION ?? 'v1'

const AUTH_BASE_URL = `${BASE_URL}/${AUTH_URL}/${API_VERSION}`
const API_BASE_URL = `${BASE_URL}/${ROOT_URL}/${API_VERSION}`

const getConfigurationWithToken = (): Configuration | undefined => {
    return AuthProvider.isAuthenticated() ? new Configuration({accessToken: AuthProvider.getServerToken()}) : undefined
}

const authApi = (): AuthApi => {
    return new AuthApi(undefined, AUTH_BASE_URL)
}

const adminApi = (): AdminApi => {
    const adminApi = new AdminApi(getConfigurationWithToken(), API_BASE_URL)
    
    //@ts-ignore
    const axiosInstance = adminApi.axios

    // Add interceptor to refresh the token when it expires
    axiosInstance.interceptors.response.use((response: AxiosResponse) => response, async function (error) {
        const originalRequest = error.config

        if (error.response?.data.code === 401 && !originalRequest._retry) { // Intercept the request if the token has expired
            originalRequest._retry = true
            log.debug('ApiClient = Original Request => ', originalRequest)

            // Refresh the token with the server
            const refreshToken = AuthProvider.getRefreshToken()
            log.debug('ApiClient => Got refresh token: ', refreshToken)
            const refreshResp = await authApi().refreshToken({refreshToken: refreshToken})

            // Set the new tokens in the local storage
            log.debug('ApiClient => Setting tokens: ', refreshResp.data.data?.requestToken, refreshResp.data.data?.refreshToken)
            AuthProvider.setTokens({
                serverToken: refreshResp.data.data?.requestToken ?? throwNewError(refreshResp.data.error), 
                refreshToken: refreshResp.data.data?.refreshToken ?? throwNewError(refreshResp.data.error)
            })

            // Retry the failed request with the new serverToken
            originalRequest.headers['Authorization'] = `Bearer ${refreshResp.data.data?.requestToken}`
            return axiosInstance(originalRequest);
        }

        return Promise.reject(error) // The token has not expired, let the request follow its flow
    })

    return adminApi
}

export const ApiClient = {
    authApi,
    adminApi
}