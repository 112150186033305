import './LogoPlaceholder.css'

interface Props {
    url?: string,
}

const LeagueLogo = ({url}: Props) => {
    if(url && url.length > 0) {
        return <img src={url} className="league-logo"/>
    }
    return <div className="league-logo logo-placeholder" />
}

export default LeagueLogo