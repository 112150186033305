import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useNavigate, useParams } from "react-router-dom"
import { Card } from "../../components/Card/Card"
import { Col, Row, Table } from "react-bootstrap"
import TeamLogo from "../../components/LogoPlaceholder/TeamLogo"
import './TeamManagementDetail.css'
import { SecondaryCard } from "../../components/SecondaryCard/SecondaryCard"
import { useEffect, useState } from "react"
import { ApiClient } from "../../services/ApiClient"
import { AdminTeamInfoFull } from "../../services/network"
import moment from "moment"
import log from "loglevel"
import { throwNewError } from "../../utils/ErrorDispatcher"
import { generateToast } from "../../utils/ToastGenerator"

const TeamManagementDetail = () => {
    
    const [team, setTeam] = useState<AdminTeamInfoFull | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const navigation = useNavigate()
    const { teamId } = useParams()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    const getTeam = async (teamId: string) => {
        try{
            let res = await ApiClient.adminApi().getTeamInfoAdmin(teamId)
            log.debug('TeamManagementDetail => getTeam =>', res.data)
            setTeam(res.data.data ?? throwNewError(res.data.error))
        } catch(e) {
            log.debug('TeamManagementDetail => getTeam => error =>', e)
            generateToast('error', e)
        }
        setLoading(false)
    }

    useEffect(() => {
        getTeam(teamId ?? '')
    }, [])

    return <>
        <AnimatedPage>
            <Header title={t('manage_teams')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                {
                    loading 
                    ? <h3 className="my-2">{t('team_management_page:team_list.loading')}</h3>  
                    : (team 
                        ?   
                            <Card>
                                <Row className="mx-2 my-2">
                                    <Col xxl={2} xs={4}>
                                        <TeamLogo url={team.teamLogoUrl} size={'big'} />
                                    </Col>
                                    <Col xs={4} className="name-container">
                                        <h1 className="my-1">{team.teamName ?? '--'}</h1>
                                    </Col>
                                    <Col></Col>
                                    <Col xs={3} className="specs-container">
                                        <p>{t('team_management_page:team_detail.league')}: {team.teamLeague ?? '--'}</p>
                                    </Col>
                                </Row>
                                <Row className="mx-2 my-4">  
                                    <Col xs={5}>
                                        <SecondaryCard
                                            title={t('team_management_page:team_detail.date')}
                                            className="display-wrapper wrap-row-start-center">
                                            <p>{team.activationDate ? moment(team.activationDate).format('DD MMMM YYYY') : '--'}</p>
                                        </SecondaryCard>  
                                    </Col>
                                    <Col xs={2}>
                                    </Col>
                                    <Col xs={5}>
                                    <SecondaryCard
                                            title={t('team_management_page:team_detail.expiration')}
                                            className="display-wrapper wrap-row-start-center">
                                            <p>{/* TODO: This is static! Change it! */}18 Marzo 2102</p>
                                        </SecondaryCard>
                                    </Col>
                                </Row>
                                <Row className="mx-2 my-4">  
                                    <Col xs={5}>
                                        <SecondaryCard
                                            title={t('team_management_page:team_detail.subscription_type')}
                                            className="display-wrapper wrap-row-start-center">
                                            <p>{/* TODO: This is static! Change it! */}Gold</p>
                                        </SecondaryCard>  
                                    </Col>
                                    <Col xs={2}>
                                    </Col>
                                    <Col xs={5}>
                                        <SecondaryCard
                                            title={t('team_management_page:team_detail.status')}
                                            className="display-wrapper wrap-row-start-center">
                                            <p>{team.subscriptionStatus ?? '--'}</p>
                                        </SecondaryCard>
                                    </Col>
                                </Row>
                                <Row className="mx-2">  
                                    <SecondaryCard title={t('team_management_page:team_detail.registered_players')}>
                                        
                                                {
                                                    team && team.players
                                                    ? <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width: '10%'}}></th>
                                                                <th>{t('team_management_page:team_detail.player')}</th>
                                                                <th>{t('team_management_page:team_detail.role')}</th>
                                                                <th>{t('team_management_page:team_detail.date')}</th>
                                                                <th>{t('team_management_page:team_detail.status_player')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                team.players?.map((player, index) => 
                                                                    <tr key={player.id ?? index}>
                                                                        <td>{<TeamLogo url={player.profilePicUrl}/>}</td>
                                                                        <td>{`${player.name} ${player.surname}`}</td>
                                                                        <td>{player.role ?? '--'}</td>
                                                                        <td>{player.activationDate ? moment(player.activationDate).format('DD MMMM YYYY') : '--'}</td>
                                                                        <td>{player.isActive ? t('team_management_page:team_detail.player_active') : t('team_management_page:team_detail.player_inactive')}</td>
                                                                    </tr>    
                                                                )
                                                            }
                                                        </tbody>
                                                        </Table>   
                                                    :   <h3 className="my-2">{t('team_management_page:team_list.no_team_found')}</h3>
                                                }
                                                                                                                         
                                    </SecondaryCard>
                                </Row>
                            </Card> 
                    :
                        <h3 className="my-2">{t('team_management_page:team_list.no_team_found')}</h3> 
                    )
                }
            </VerticalScrollableContent>
        </AnimatedPage>
    </>
}

export { TeamManagementDetail }
