import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useNavigate } from "react-router-dom"
import { Card } from "../../components/Card/Card"
import { NewMessageRequest, TeamNewCommunicationForm } from "../../forms/TeamNewCommunicationForm/TeamNewCommunicationForm"
import { useRef } from "react"
import { ApiClient } from "../../services/ApiClient"
import { Button } from "react-bootstrap"
import log from "loglevel"
import { yupTeamCommunicationValidationSchema } from "../../utils/form-validation-schemas"
import { generateToast } from "../../utils/ToastGenerator"
import { TEAM_COMMUNICATION } from "../../routing/routes"
import { throwNewError } from "../../utils/ErrorDispatcher"

const TeamCommunicationNew = () => {
    
    const navigation = useNavigate()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    const formRef = useRef<any>()
    const submitForm = () => formRef.current && formRef.current.handleSubmit()

    const handleForm = async (newMessage: NewMessageRequest) => {
        try {
            let res = await ApiClient.adminApi().sendManagerAdminMessage(
                newMessage.body, 
                newMessage.managerId, 
                newMessage.title, 
                undefined, 
                undefined, 
                newMessage.media
                )
            log.debug('TeamCommunication => sendManagerAdminMessage =>', res.status)
            // Redirect to the page where the new conversation is shown
            navigation(`${TEAM_COMMUNICATION}/${res.data?.data?.threadId ?? throwNewError(res.data.error)}`)
        }
        catch(e) {
            log.debug("Error sending manager admin message")
            generateToast('error', e)
        }
    }

    return <>
        <AnimatedPage>
            <Header title={t('communication_team_new')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
            <Card titleClassName="m-0" bodyClassName="pb-4">
                <div>
                    <TeamNewCommunicationForm
                        validationSchema={yupTeamCommunicationValidationSchema}
                        onSubmitCallback={handleForm}
                        formRef={formRef}/>
                </div>
                <div className="text-end">
                    <Button variant="primary" 
                            className="btn-md btn-rounded mt-3 text-uppercase"
                            onClick={submitForm}>
                                {t('team_communication_page:buttons.new_communication')}
                    </Button>
                </div>
            </Card>
            </VerticalScrollableContent>
        </AnimatedPage>
    </>
}

export { TeamCommunicationNew }