import * as Yup from "yup";
import log from 'loglevel'

const emailRule = {
    email: Yup.string()
        .email('invalidEmail')
        .required('required'),
}

const passwordRule = {
    password: Yup.string()
        .min(8, 'passwordMinLength')
        .required('required')
}

const reasonTypeRule = {
    reasonType: Yup.string()
        .required('required')
}

const reasonDescriptionRule = {
    reasonDescription: Yup.string()
        .required('required')
}

const acceptedRule = {
    accepted: Yup.boolean()
        .required('required')
}

const descriptionRule = {
    description: Yup.string()
        .required('required')
}

const bodyRule = {
    body: Yup.string()
        .required('required')
}

const threadIdRule = {
    threadId: Yup.string()
        .required('required')
}

const categoryRule = {
    category: Yup.string()
        .required('required')
}

const titleRule = {
    title: Yup.string()
        .required('required')
}

const managerIdRule = {
    managerId: Yup.string()
        .required('manager_required_in_set')
}

const loginValidationSchema = {
    ...emailRule,
    ...passwordRule,
}

const teamUnlockValidationSchema = {
    ...acceptedRule,
    ...reasonTypeRule,
    ...reasonDescriptionRule
}

const contactAdminValidationSchema = {
    ...titleRule,
    ...bodyRule,
    ...categoryRule
}

const answerAdminValidationSchema = {
    ...titleRule,
    ...bodyRule,
    ...categoryRule,
    ...threadIdRule
}

const teamCommunicationValidationSchema = {
    ...managerIdRule,
    ...titleRule,
    ...bodyRule
}

const teamCommunicationReplyValidationSchema = {
    ...bodyRule,
    ...threadIdRule
}

export const yupEmailValidationSchema = Yup.object().shape(emailRule)

export const yupLoginValidationSchema = Yup.object().shape(loginValidationSchema)

export const yupTeamUnlockValidationSchema = Yup.object().shape(teamUnlockValidationSchema)

export const yupContactAdminValidationSchema = Yup.object().shape(contactAdminValidationSchema)

export const yupAnswerAdminValidationSchema = Yup.object().shape(answerAdminValidationSchema)

export const yupTeamCommunicationValidationSchema = Yup.object().shape(teamCommunicationValidationSchema)

export const yupTeamCommunicationReplyValidationSchema = Yup.object().shape(teamCommunicationReplyValidationSchema)