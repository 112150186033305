import { Card } from "../../components/Card/Card"
import { Table, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { ManagerAdminThreadSummary } from "../../services/network"
import { useEffect, useState } from "react"

interface TableCommunicationSummaryProps {
    communicationsList : ManagerAdminThreadSummary[],
    onCommunicationSelected : Function,
    onThreadChecked: (threads: number[]) => void
}

const TableCommunicationSummary = ({
    communicationsList,
    onCommunicationSelected,
    onThreadChecked
    }: TableCommunicationSummaryProps) => {
    const {t} = useTranslation()
    const [checkedThreadsIds, setCheckedThreadIds] = useState<number[]>([])
    
    useEffect(()=>{
        onThreadChecked(checkedThreadsIds)
    },[checkedThreadsIds])

    

    return (
        <Card>
            <div>
                <Table responsive hover className={'table-communications'}>
                    <thead>
                        <th>
                            <Form.Group >
                                <Form.Check type={"checkbox"}>
                                    <Form.Check.Input type={"checkbox"} 
                                        onChange={(e: any) => {
                                            if (e.currentTarget.checked) {
                                                setCheckedThreadIds(communicationsList.map((comm) => parseInt(comm.threadId!,10)))
                                            }
                                            else {
                                                setCheckedThreadIds([])
                                            }}}
                                    />
                                </Form.Check>
                            </Form.Group>
                        </th>
                        <th>{t('team_communication_page:list.sender')}</th>
                        <th>{t('team_communication_page:list.team')}</th>
                        <th>{t('team_communication_page:list.subject')}</th>
                        <th>{t('team_communication_page:list.date')}</th>
                    </thead>
                    <tbody>
                        {
                            communicationsList && communicationsList.map((communication, index) => {
                            const rowSelected = () => onCommunicationSelected(communication.threadId)
                                return (
                                    <tr 
                                        
                                        key={index}>
                                        <td>
                                            <Form.Group >
                                                <Form.Check type={"checkbox"}>
                                                    <Form.Check.Input   type={"checkbox"} 
                                                                        value={communication.threadId} 
                                                                        checked={checkedThreadsIds.findIndex(threadId => threadId == parseInt(communication.threadId!,10)) > -1}
                                                                        onChange={(e: any) => {
                                                                            if(e.currentTarget.checked){
                                                                                
                                                                                setCheckedThreadIds(prevState => [...prevState, parseInt(e.target.value,10)])
                                                                            } else {
                                                                                setCheckedThreadIds(prevState => prevState.filter(threadId => threadId != parseInt(e.target.value,10)))
                                                                            }
                                                                            }}
                                                                        />
                                                </Form.Check>
                                            </Form.Group>
                                        </td>
                                        <td className={!communication.adminRead ? 'table-text-bold' : ''} onClick={rowSelected}>{`${communication.managerName} ${communication.managerSurname}`}</td>
                                        <td className={!communication.adminRead ? 'table-text-bold' : ''} onClick={rowSelected}>{communication.teamName}</td>
                                        <td className={!communication.adminRead ? 'table-text-bold' : ''} onClick={rowSelected}>{communication.title}</td>
                                        <td className={!communication.adminRead ? 'table-text-bold' : ''} onClick={rowSelected}>{moment(communication.updatedAt).format('LLL')}</td>
                                    </tr>)
                                }
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </Card>
    )
}

export { TableCommunicationSummary }