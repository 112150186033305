import classNames from "classnames"

interface CardProps extends React.HTMLAttributes<HTMLElement> {
    title?: string,
    titleClassName?: string,
    bodyClassName?: string
}

const Card = ({title, titleClassName, bodyClassName, ...props}:CardProps) => {
    return(
        <>
            <div className={classNames('card', props.className)} style={props.style}>
                {title && <h5 className={classNames('card-title card-title-shadow',titleClassName)}>{title}</h5>}
                <div className={classNames("card-body", bodyClassName)}>
                    {props.children}
                </div>
            </div>
        </>
    )
}

export { Card }