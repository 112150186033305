import { Formik } from 'formik';
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import log from 'loglevel';
import { FileWrapper, MultimediaFileChooser } from '../../components/MultimediaFileChooser/MultimediaFileChooser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { FilePreviewViewer } from '../../components/FilePreviewViewer/FilePreviewViewer';

interface NewReplyMessageRequest {
    threadId: string
    body: string,
    // category: string,
    media: File[] | undefined
}

interface TeamNewCommunicationFormProps {
    threadId: string,
    validationSchema: any,
    onSubmitCallback: Function,
    formRef: any
}
const TeamCommunicationReplyForm = ({threadId, validationSchema, onSubmitCallback, formRef}: TeamNewCommunicationFormProps) => {
    
    const { t } = useTranslation()

    const initialValues: NewReplyMessageRequest = {threadId: threadId, body: '', media: undefined}
    
    return(
        <div>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema} 
                onSubmit={values => {onSubmitCallback(values)}}
                initialValues={initialValues}
                innerRef = {formRef}>
                    {formik => {
                        const { handleSubmit, values, touched, errors, getFieldProps, setFieldValue } = formik
                        return (
                            <Form onSubmit={handleSubmit} noValidate>
                                <Form.Group controlId="body">
                                    <Form.Label className="col-form-label-sm">
                                        {t('team_new_communication_page:body')}
                                    </Form.Label>
                                    <Form.Control 
                                        type="body"
                                        as="textarea"
                                        style={{'height': '2rem'}}
                                        {...getFieldProps("body")}
                                        isInvalid={!!touched.body && !!errors.body}/>
                                        
                                    <Form.Control.Feedback type="invalid">
                                        {errors.body ? <div>{t(`yup:${errors.body}`)}</div> : null}
                                    </Form.Control.Feedback>
                                <p></p>
                                </Form.Group>
                                <MultimediaFileChooser 
                                    className='form-control'
                                    style={{'height': 'initial'}}
                                    acceptedFiles={{"image/*": ['.jpeg', '.jpg', '.png'] /*, "application/pdf":['.pdf']*/}}
                                    maxFilesAccepted = {1}
                                    disableDragNDrop = {false}
                                    onFilesDrop={(accFiles: FileWrapper[], rejFiles: FileWrapper[]) => {
                                        log.debug("in onFilesDrop files => ", [accFiles, rejFiles])
                                        if(!accFiles.length) return
                                        values.media ? values.media.push(accFiles[0].file) : values.media = [accFiles[0].file]
                                        setFieldValue('media', values.media)
                                    }}
                                    uploadButtonLabel={t('team_communication_page:buttons.upload')}
                                    >
                                    {!values.media && <FontAwesomeIcon icon={faCloudUpload} size={'6x'}/>}
                                    <div className="display-wrapper wrap-auto flex-wrap">
                                    {values.media && values.media.map((file, index) => {
                                        return (
                                            <FilePreviewViewer 
                                                className={'text-center image-preview'}
                                                file={file} 
                                                key={index}
                                                showDeleteButton={true}
                                                onDelete={(e:any) => {
                                                    values.media?.splice(index, 1)
                                                    setFieldValue('media', values.media?.length ? values.media : undefined)
                                                }}
                                            />
                                    )})}
                                    </div>
                                </MultimediaFileChooser>
                            </Form>
                        )
                    }}
                </Formik>
        </div>
    )
}

export type { NewReplyMessageRequest }

export { TeamCommunicationReplyForm }