import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, FormControl, InputGroup, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FC, useEffect, useState } from 'react'
import {useDebounce} from 'use-debounce'
import log from 'loglevel'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    value: string,
    onQueryChange: Function
}

export const SearchBar:FC<Props> = ({value, onQueryChange, ...props}) => {
    const [query, setQuery] = useState<string>(value)
    const [debouncedQuery] = useDebounce(query, 800)
    
    const onClearSearch = () => {
        log.debug("in onClearSearch")
        setQuery('')
    }

    const onSearch = () => {
        if(query) onQueryChange(query)
    }

    useEffect(()=>{
        log.debug("in useEffect debouncedQuery => ", debouncedQuery)
        onQueryChange(debouncedQuery)
    }, [debouncedQuery])

    return(
        <>
            <div {...props}>
                <div className='searchbar display-wrapper'>
                    <FormControl 
                        style={{'backgroundColor': '#1b1428'}}
                        className="search-form-control" 
                        placeholder="Cerca..." 
                        onChange={(e) => setQuery(e.target.value)} 
                        value={query}/>
                    { query !== "" && 
                        <Button variant="search" id="button-clear" onClick={()=> onClearSearch()}>
                            <FontAwesomeIcon icon={faTimesCircle} color="white"/>
                        </Button>
                    }
                    <Button variant="search" id="button-search" onClick={() => onSearch()}>
                        <FontAwesomeIcon icon={faSearch} color="white"/>
                    </Button>
                </div>
            </div>
        </>
    )
}