import moment from "moment"
import LeagueLogo from "../../components/LogoPlaceholder/LeagueLogo"
import TeamLogo from "../../components/LogoPlaceholder/TeamLogo"
import { Table } from "react-bootstrap"
import { Card } from "../../components/Card/Card"
import { useTranslation } from "react-i18next"
import { AdminTeamSummary } from "../../services/network"

const TeamsTable = ({teams, onTeamSelected}: {teams: AdminTeamSummary[], onTeamSelected: Function}) => {

    const {t} = useTranslation()

    return(
        <>
            <Card>
                <div>
                    <Table responsive hover className={'table-communications'}>
                        <thead>
                            <tr>
                                <th className="text-center" style={{width: '10%'}}>{t('team_management_page:team_list.league')}</th>
                                <th className="text-center" style={{width: '10%'}}>{t('team_management_page:team_list.team')}</th>
                                <th></th>
                                <th className="text-center" style={{width: '20%'}}>{t('team_management_page:team_list.team_name')}</th>
                                <th></th>
                                <th className="text-center">{t('team_management_page:team_list.date')}</th>
                                <th className="text-center">{t('team_management_page:team_list.status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                teams.map(team => {
                                    const rowSelected = () => onTeamSelected(team.teamId)
                                    return <tr className='team-row' onClick={rowSelected} key={team.teamId} >
                                                <td className="text-center">{<LeagueLogo url={team.teamLeagueLogoUrl}/>}</td>
                                                <td className="text-center">{<TeamLogo url={team.teamLogoUrl}/>}</td>
                                                <td></td>
                                                <td className="text-center">{team.teamName ?? 'no_name'}</td>  
                                                <td></td>                                              
                                                <td className="text-center">{team.activationDate ? moment(team.activationDate).format('LL') : '-'}</td>
                                                <td className="text-center">{team.status ?? 'no_status'}</td>
                                        </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Card>
        </>
    )
}

export { TeamsTable }