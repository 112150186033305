import Slider, {Settings} from "react-slick"
import log from "loglevel"
import React, { FC, Key, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf, faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export type CarouselItem = {
    title: string,
    src: string,
    mimeType: string,
    thumbnail?: string
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    items: CarouselItem[],
    allowOnVideoClick?: boolean,
    onItemSelected?: (item: CarouselItem) => void
    //carouselOptions?: Settings
}

const CarouselItem:FC<React.PropsWithChildren<{onItemClick?: (item: CarouselItem) => void, item?: CarouselItem, key: Key}>> = ({onItemClick, item, key, ...props}) => {
    return <div onClick={()=>{
        log.debug("carousel item clicked")
        if(onItemClick && item) onItemClick(item)
    }} key={key}>
        {props.children}
    </div>
}

export const Carousel: FC<Props & Settings> = ({items, allowOnVideoClick = false, onItemSelected, ...props}) => {

    var stopVideos = function () {
        log.debug("try stopping videos")
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        });
    };
    
    return(
        <>
            <Slider 
                className={props.className}
                {...props}
                beforeChange={(currentSlide:number) => {
                    log.debug("beforeChange current slideeeeeeeeee => ", currentSlide)
                    stopVideos()
                    if(props.afterChange) props.afterChange(currentSlide)
                }}
            >
                {
                    items.map(item => {
                        log.debug('Inside Carousel => item src => ', item.src)
                        return <CarouselItem onItemClick={onItemSelected} item={item} key={item.src}>
                            <div className={classNames("item", {'cursor-pointer': onItemSelected})}>
                                <>
                                {(item.mimeType?.toLowerCase()?.match(/(img)|(image)/)?.length ||
                                item.src?.split('.')?.pop()?.match(/(jpeg)|(jpg)|(png)/)?.length) ?
                                    item.src ? 
                                    <img src={item.src} alt="carousel-item" /> :
                                    <FontAwesomeIcon icon={faImage} size={"4x"}/>
                                    :
                                    <FontAwesomeIcon icon={faFilePdf} size={"4x"}/>
                                }
                                </>
                                {item.title && <p className="text-start ellipsis">{item.title}</p>}
                            </div>
                        </CarouselItem>
                    })
                }
            </Slider>
        </>
    )
}