import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import log from "loglevel"
import { ApiClient } from "../../services/ApiClient"
import _ from 'lodash'
import { UserState } from "../types"


const errorParser = (error:any) => {
    log.debug("in errorParser, error => ", error)
    log.debug("in errorParser, error.response => ", error.response)
    const errorCode = error.response?.status
    const errorData = error.response?.data
    
    switch(errorCode){
        case 400: {
            if(errorData){
                switch(errorData.error){
                    case 101: //Bad params
                        return error.response.config?.url==="/password" ? "La password corrente inseritia non è valida" : "I dati inseriti non sono validi"
                    case 102: //Missing Params
                        return "Non è possibile completare l'operazione a causa di campi mancanti"
                    case 103: //Database error
                        return "Si è verificato un errore lato server"
                    default:
                        return error.response.data.message
                }
            }
            return "" //should never be the case
        }
        case 401: {
            if(errorData && errorData.error === 403){
                return "La sessione di navigazione è scaduta"
            } else {
                return "La navigazione è scaduta"
            }
        }
        case 404: 
            return "L'API richiesta non è stata trovata"
        default:
            return error.message? error.message : error
    }
}

// export const getUser = createAsyncThunk(
//     'user/profile',
//     async (queryParams, thunkAPI) =>{
//         try{
//             let resp = await API.getCompanyProfile(queryParams)
//             log.debug("getUserProfile resp => ", resp)
//             thunkAPI.dispatch(setUser(resp.data.data))
//         } catch(error){
//             log.error(`getUserProfile error catch => ${error}`)
//             log.error(`getUserProfile error catch => `, error.response)
//             return thunkAPI.rejectWithValue(errorParser(error))
//         }
//     }
// )

/*export const updateUser = createAsyncThunk(
    'user/update',
    async (body, thunkAPI) =>{
        try{
            let resp = await API.updateUser()
            log.debug("updateUser resp => ", resp)
            thunkAPI.dispatch(setUser(resp.data?.data))
        } catch(error: any){
            log.error(`updateUser error catch => ${error}`)
            log.error(`updateUser error catch => `, error.response)
            return thunkAPI.rejectWithValue(errorParser(error))
        }
    }
)*/

const initialUserState: UserState = {
    user: undefined
}


//Slice
const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser: (state, action) => {
            log.debug("[reducer] setUser, action => ", action)
            //state = {email: action.payload}

            state.user = {
                ...state.user,
                ...action.payload, 
                //last_update: moment().format()
            }
            return state  
        },
        resetUser: (state, action) => {
            log.debug("[reducer] resetUser, action => ", action)
            state = initialUserState
            return state
        },
    }
})
export default userSlice.reducer

//Actions
export const {
    setUser,
    resetUser,
} = userSlice.actions