import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useLocation, useNavigate } from "react-router-dom"

const NoMatch = () => {

    const navigation = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    return(
        <>
            <AnimatedPage>
                <Header title={t('page_not_found_title')} onBackClick={onBackClick}/>
                <VerticalScrollableContent>
                    <h1>{t('page_not_found_descr')}{location.pathname}</h1>
                </VerticalScrollableContent>
            </AnimatedPage>
        </>)
}

export default NoMatch