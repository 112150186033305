import log from "loglevel"
import { useAppDispatch } from '../../hooks/customReduxHooks';
import { login } from "../../redux/reducers/auth.slice";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "../../routing/routes";
import { Button, Col, Row } from "react-bootstrap"
import LoginForm from "../../forms/LoginForm/LoginForm";
import { yupLoginValidationSchema } from "../../utils/form-validation-schemas";
import { useRef, useState } from "react";
import { AuthCredentials } from "../../services/network";
import { useTranslation } from "react-i18next"
import { Card } from "../../components/Card/Card";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Login = () => {

    const { t } = useTranslation(['login_page'])
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const formRef = useRef<any>()

    const submitForm = () => {
        log.debug('Login => submitForm')
        setLoading(true)
        formRef.current && formRef.current.handleSubmit()        
    }

    const performLogin = async (body: AuthCredentials) => {
        log.debug('login => body =>', body);   
        setLoading(true)
        
        dispatch(login({
            email: body.email,
            password: body.password,
        }))
        .unwrap()
        .then(response => {
            log.debug('[Login] => OK =>', response);
            navigate(DASHBOARD_PATH, {replace: true});
        })
        .catch(err => {
            log.debug('[Login] => ERROR =>', err)
            if(axios.isAxiosError(err))
                log.debug('[Login] => ERROR => axios error')
            if(axios.isCancel(err))
                log.debug('[Login] => ERROR => axios cancel')
        })  
        .finally(() => setLoading(false))
    }

    return <>
        <Row className="align-items-center align-self-center justify-content-center min-vh-100">
            <Col md={6}>
                <Card>
                    <div className="login m-xs-4 m-0">
                        <div className="brand-logo text-center pb-3">
                            <img src={require('../../assets/images/logo.png')} alt="logo" />
                        </div>
                        <LoginForm 
                            initialValues={{email: "", password: ""}}
                            validationSchema={yupLoginValidationSchema}
                            onSubmitCallback={performLogin}
                            isSubmitting={false}
                            formRef={formRef}
                        />
                        <div className="text-end py-5">
                            {!loading && 
                                <Button variant="primary" className="btn-rounded btn-md text-uppercase" type="submit" onClick={submitForm}>{t('login_page:buttons.login_btn_label')}</Button>
                            }
                            {loading && <FontAwesomeIcon icon={faArrowRotateRight} className="fa-spin"/>}
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    </>
}

export default Login