import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

const toastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
}

const generateToast = (type: 'success' | 'error', message?: any, onClose?: <T = {}>(props: T) => void) => {
    let parsedMessage
    if (typeof message === 'string') {
        parsedMessage = `${message}`
    } else {
        parsedMessage = `API Error: ${message.response?.data?.error ?? 'Generic API Error'} - Code: ${message.response?.data?.code ?? 'No Code'}`
    }
    
    switch (type) {
        case 'success':
            return toast.success(parsedMessage, {...toastOptions, onClose: onClose}) // TODO: Fix onClose behaviour!
        case 'error':
            return toast.error(parsedMessage, {...toastOptions, onClose: onClose})
    }
}

export { generateToast }