
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import {Navbar} from '../../components/Navbar/Navbar'

const MainPage = () => {

    return (
        <> 
            <div className="container-scroller sidebar-fixed">
                <div className='sidebar-wrapper'>
                    <Sidebar />
                </div>
                {/* container-fluid */}
                <div className="page-body-wrapper">
                    <Navbar />
                    {/* <AnimatedRoutes /> */}
                    <Outlet/>
                    {/* <div className="main-panel">
                        <div className="content-wrapper">
                            <AnimatedRoutes />
                        </div>
                        <Footer />
                    </div> */}
                </div>
            </div>
        </>
    )
}
export default MainPage