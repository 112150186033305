export const DASHBOARD_PATH = "/admin"
export const LOGIN_PATH = "/login"
export const TEAM_MANAGEMENT = "/admin/teams-management"
export const TEAM_MANAGEMENT_DETAIL = "/admin/teams-management/:teamId"

export const TEAM_UNLOCK = "/admin/teams-unlock"
export const TEAM_UNLOCK_DETAIL = "/admin/teams-unlock/:requestId"

export const TEAM_COMMUNICATION = "/admin/teams-communication"
export const TEAM_COMMUNICATION_DETAIL = "/admin/teams-communication/:threadId"
export const TEAM_COMMUNICATION_NEW = "/admin/teams-communication/new"

export type MenuItem = {
    path: string,
    state?: string,
    label?: string,
    i18_label_key: string,
    nestedMenuItems?: MenuItem[]
}

export const menuItems: MenuItem[] = [
    {
        path: DASHBOARD_PATH,
        label: "Dashboard",
        state: 'dashboardOpen',
        i18_label_key: "dashboard"
    },
    {
        path: TEAM_MANAGEMENT,
        label: "Gestione Squadre",
        state: 'managementOpen',
        i18_label_key: "manage_teams"
    },
    {
        path: TEAM_UNLOCK,
        label: "Sblocca Squadre",
        state: 'unlockOpen',
        i18_label_key: "unlock_teams"
    },
    {
        path: TEAM_COMMUNICATION,
        label: "Comunicazioni Squadre",
        state: 'communicationOpen',
        i18_label_key: "communication_teams"
    }
]