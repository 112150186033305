import { FC, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useDropzone, Accept, FileError, FileRejection } from "react-dropzone"
import { useTranslation } from "react-i18next";

//TODO syscakeify

export type FileWrapper = {
    file: File,
    errors: FileError[]
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    allowMultipleFile?: boolean,
    acceptedFiles?: Accept,
    disableDragNDrop?: boolean,
    onFilesDrop: (acceptedFiles: FileWrapper[], rejectedFiles: FileWrapper[]) => void,
    maxFilesAccepted?: number,
    customValidator?: Function
    uploadButtonLabel: String
    showHints?: boolean
}

export const MultimediaFileChooser: FC<React.PropsWithChildren<Props>> = ({
    allowMultipleFile = false, 
    acceptedFiles: accetedFiles, 
    onFilesDrop,
    disableDragNDrop: allowDragNDrop = false,
    maxFilesAccepted = 0,
    customValidator = (file:any)=>{},
    uploadButtonLabel,
    showHints = false,
    ...props
}) => {
    const { t } = useTranslation('common')

    const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
        const mappedAcc = accFiles.map((file) => ({ file, errors: []}));
        const mappedRej = rejFiles.map((r) => ({ ...r}));
        onFilesDrop(mappedAcc, mappedRej)
    }, []);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept: accetedFiles,
        multiple: allowMultipleFile,
        onDrop,
        maxFiles: maxFilesAccepted,
        noDrag: allowDragNDrop,
        noClick: true
        //validator: customValidator
    });

    return(
        <>
            <div className={props.className} style={props.style}>
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="drop-text">
                        {isDragActive ? (
                            <span>Drop del file qui...</span>
                        ) : (
                            <span>
                                {
                                    showHints && (allowMultipleFile ? t('multimedia_multiple_drag_n_drop') : t('multimedia_single_drag_n_drop'))
                                }
                            </span>
                        )}
                    </div>
                    <div className="my-3">
                        {props.children}
                    </div>
                    <Button variant={'primary'} className={'btn-xs btn-rounded text-uppercase mt-2'} onClick={open}>{uploadButtonLabel}</Button>
                </div>
            </div>
        </>
    )
}