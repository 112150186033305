import { FC, SyntheticEvent, useCallback, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { generateImageFromFile, generateVideoFromFile } from '../../utils/FileUtils';
import classNames from "classnames";
import log from "loglevel";
import { Modal } from "react-bootstrap";
import { Carousel, CarouselItem } from "../Carousel/Carousel";

export const FullScreenFileModal = (show: boolean, setShow: Function, file?: File,  url?: string, carouselItems?: CarouselItem[], initialCarouselIndex?:number, onItemSelected?: (item: CarouselItem) => void) => {
    return(
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
                centered
            >
                <Modal.Body className="text-center">
                    <Modal.Header closeButton closeVariant="white"></Modal.Header>
                    {(url || file) && <img className="img-thumbnail" src={!url ? (generateImageFromFile(file)) : url} />}
                    {/* {file && <img className="img-thumbnail" src={!url ? (generateImageFromFile(file)) : url} />} */}
                    {
                        carouselItems &&
                        <Carousel 
                            items={carouselItems}
                            className="portfolio-slider center mt-4"
                            dots
                            initialSlide={initialCarouselIndex}
                            onItemSelected={onItemSelected}
                            slidesToShow={1}
                            slidesToScroll={1}
                            focusOnSelect
                            centerMode
                            adaptiveHeight = {false}
                            swipeToSlide = {false}
                            arrows = {true}
                            allowOnVideoClick={true}
                            afterChange={(currentSlide: number) => {
                                log.debug("afterChange current slide => ", currentSlide)
                            }}
                            
                        />
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}


export interface Props extends React.HTMLAttributes<HTMLElement> {
    file?: File,
    showDeleteButton?: boolean,
    showEditButton?: boolean,
    onDelete?: (e: any) => void,
    onEdit?: (e: any) => void
}

export const FilePreviewViewer: FC<Props> = ({
    file,
    showDeleteButton = false, 
    showEditButton = false,
    onDelete = undefined,
    onEdit = undefined,
     ...props
}) => {
    const [showFullScreen, setShowFullScreen] = useState<boolean>(false)
    log.debug("file => ", file)
    return(
        <>
        {
            showFullScreen && FullScreenFileModal(showFullScreen, setShowFullScreen, file)
        }
        {
            file?.type?.includes('image') && ImageFilePreview(file, setShowFullScreen, showDeleteButton, showEditButton, onDelete, onEdit, props)
        }
        {
            file?.type?.includes('video') && VideoFilePreview(file, setShowFullScreen, showDeleteButton, showEditButton, onDelete, onEdit, props)
        }
        {
            file?.type?.includes('pdf') && PDFPreview(file, setShowFullScreen, showDeleteButton, showEditButton, onDelete, onEdit, props)
        }
        </>
    )
}

const ImageFilePreview = (
    file: File, 
    showItemFullScreen: Function,
    showDeleteButton: boolean, 
    showEditButton: boolean, 
    onDelete: Function | undefined, 
    onEdit: Function | undefined,
    props: React.HTMLAttributes<HTMLElement>
) => {
    const onDeleteClick = useCallback((e: any)=>{
        log.debug("in onDeleteClick")
        if(onDelete) onDelete(e)
    },[])

    const onEditClick = useCallback((e: SyntheticEvent)=>{
        log.debug("in onEditClick")
        e.preventDefault()
        if(onEdit) onEdit(e)
    },[])

    const showImage = () => {
        showItemFullScreen(true)
    }

    return(
        // <div {...props} className={classNames('display-wrapper justify-center', props.className)}>
        //     <div className="img-fluid aligner-wrapper">
        //         <img className="img-thumbnail img-xxl" src={(generateImageFromFile(file))}></img>
        //         <div className={'absolute top'} style={{'top': '-10px', 'right':'-10px'}} onClick={()=>{
        //             log.debug("on delete click")
        //         }}>
        //             <FontAwesomeIcon icon={faTrash} color={'red'} style={{'fontSize': '1.3rem'}}/>
        //         </div>
        //     </div>
        // </div>
        


        <div {...props} className={classNames(props.className)}>
            <img 
                className={classNames(
                    "img-thumbnail img-xxl cursor-pointer"
                )} 
                src={(generateImageFromFile(file))} 
                onClick={showImage}>
            </img>
            <div className="mt-2">
                {   showEditButton &&
                    <span className="me-2 cursor-pointer" onClick={onEditClick}>
                        <FontAwesomeIcon icon={faEdit} style={{'fontSize': '1.3rem'}}/>
                    </span>
                }
                {   showDeleteButton &&
                    <span className="cursor-pointer" onClick={onDeleteClick}>
                        <FontAwesomeIcon icon={faTrash} style={{'fontSize': '1.3rem'}}/>
                    </span>
                }
            </div>
        </div>
    )
}

const VideoFilePreview = (
    file: File, 
    showItemFullScreen: Function,
    showDeleteButton: boolean, 
    showEditButton: boolean, 
    onDelete: Function | undefined, 
    onEdit: Function | undefined, 
    props: React.HTMLAttributes<HTMLElement>
) => {

    const onDeleteClick = useCallback((e: any)=>{
        log.debug("in onDeleteClick")
        if(onDelete) onDelete(e)
    },[])

    const onEditClick = useCallback((e: SyntheticEvent)=>{
        log.debug("in onEditClick")
        e.preventDefault()
        if(onEdit) onEdit(e)
    },[])

    const showVideo = () => {
        showItemFullScreen(true)
    }

    return(

        <div {...props} className={classNames(props.className)}>
            <video 
                //width="400" 
                height={"150"}
                controls 
                onClick={showVideo}
            >
                <source src={generateVideoFromFile(file)}/>
            </video>
            <div className="mt-2">
                {   showEditButton &&
                    <span className="me-2 cursor-pointer" onClick={onEditClick}>
                        <FontAwesomeIcon icon={faEdit} style={{'fontSize': '1.3rem'}}/>
                    </span>
                }
                {   showDeleteButton &&
                    <span className="cursor-pointer" onClick={onDeleteClick}>
                        <FontAwesomeIcon icon={faTrash} style={{'fontSize': '1.3rem'}}/>
                    </span>
                }
            </div>
        </div>
    )
}

const PDFPreview = (
    file: File, 
    showItemFullScreen: Function,
    showDeleteButton: boolean, 
    showEditButton: boolean, 
    onDelete: Function | undefined, 
    onEdit: Function | undefined, 
    props: React.HTMLAttributes<HTMLElement>
) => {

    const onDeleteClick = useCallback((e: any)=>{
        log.debug("in onDeleteClick")
        if(onDelete) onDelete(e)
    },[])

    const onEditClick = useCallback((e: SyntheticEvent)=>{
        log.debug("in onEditClick")
        e.preventDefault()
        if(onEdit) onEdit(e)
    },[])

    const showPDF = () => {
        showItemFullScreen(true)
    }

    return(

        <div {...props} className={classNames(props.className)}>
            {/* <img 
                className="img-thumbnail img-xxl cursor-pointer" 
                src={(generateImageFromFile(file))} 
                //onClick={showImage}
            ></img> */}
            <div 
                className={
                    classNames(
                        "img-thumbnail img-xxl cursor-pointer d-flex align-content-center justify-content-center align-items-center",
                        {"mx-auto": props.className?.includes("text-center")}
                    )
                }
                onClick={showPDF}>
                <FontAwesomeIcon icon={faFilePdf} size={"6x"} color="#b4b4b4"/>
            </div>
            
            <div className="mt-2">
                {   showEditButton &&
                    <span className="me-2 cursor-pointer" onClick={onEditClick}>
                        <FontAwesomeIcon icon={faEdit} style={{'fontSize': '1.3rem'}}/>
                    </span>
                }
                {   showDeleteButton &&
                    <span className="cursor-pointer" onClick={onDeleteClick}>
                        <FontAwesomeIcon icon={faTrash} style={{'fontSize': '1.3rem'}}/>
                    </span>
                }
            </div>
        </div>
    )
}