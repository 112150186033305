import { Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Header from "../../components/Header/Header"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import {DashboardCard} from "../../components/DashboardCard/DashboardCard"
import communicationsIcon from '../../assets/icons/ic_communications.svg'
import managementIcon from '../../assets/icons/ic_teams_management.svg'
import unlockIcon from '../../assets/icons/ic_teams_unlock.svg'
import { TEAM_COMMUNICATION, TEAM_MANAGEMENT, TEAM_UNLOCK } from "../../routing/routes"
import { useTranslation } from "react-i18next"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"


const Dashboard = () => {

    const navigation = useNavigate()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    return <>
        <AnimatedPage key={'admin/dashboard'}>
            <Header title={'Admin'} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                <Row>
                    <DashboardCard 
                        title={t('manage_teams')} 
                        icon={managementIcon} 
                        destination={TEAM_MANAGEMENT}/>
                    <DashboardCard 
                        title={t('unlock_teams')} 
                        icon={unlockIcon} 
                        destination={TEAM_UNLOCK}/>
                    <DashboardCard 
                        title={t('communication_teams')} 
                        icon={communicationsIcon} 
                        destination={TEAM_COMMUNICATION}/>
                </Row>
            </VerticalScrollableContent>
        </AnimatedPage>
    </>
}

export default Dashboard