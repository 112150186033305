import { Formik } from 'formik';
import { Form } from "react-bootstrap"
import './TeamNewCommunicationForm.css'
import { useTranslation } from "react-i18next"
import log from 'loglevel';
import { FileWrapper, MultimediaFileChooser } from '../../components/MultimediaFileChooser/MultimediaFileChooser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { FilePreviewViewer } from '../../components/FilePreviewViewer/FilePreviewViewer';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useState } from 'react';
import { AdminTeamManagersInner } from '../../services/network';
import { ApiClient } from '../../services/ApiClient';
import { generateToast } from '../../utils/ToastGenerator';
import { throwNewError } from '../../utils/ErrorDispatcher';

interface NewMessageRequest {
    managerId: string,
    title: string,
    body: string,
    // category: string,
    media: File[] | undefined
}

interface TeamNewCommunicationFormProps {
    validationSchema: any,
    onSubmitCallback: Function,
    formRef: any
}
const TeamNewCommunicationForm = ({validationSchema, onSubmitCallback, formRef}: TeamNewCommunicationFormProps) => {
    
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false);
    const [managers, setManagers] = useState<AdminTeamManagersInner[]>([]);

    const initialValues: NewMessageRequest = {managerId: '', title: '', body: '', media: undefined}

    const handleSearch = async (queryString: string) => {
        setIsLoading(true);
        log.debug('queryString =>', queryString)
        try{
            let res = await ApiClient.adminApi().getTeamsManagers(queryString)
            log.debug('TeamCommunicationNew => handleSearch =>', res.data)
            setManagers(res.data.data ?? throwNewError(res.data.error))
        } catch(e) {
            log.debug('TeamCommunicationNew => handleSearch => error =>', e)
            generateToast('error', e)
        }
        setIsLoading(false)
    }
    
    return(
        <div>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema} 
                onSubmit={values => {onSubmitCallback(values)}}
                initialValues={initialValues}
                innerRef = {formRef}>
                    {formik => {
                        const { handleSubmit, values, touched, errors, getFieldProps, setFieldValue } = formik
                        return (
                            <Form onSubmit={handleSubmit} noValidate>
                                
                                <Form.Group controlId="managerId">
                                    <Form.Label className="col-form-label-sm">
                                        {t('team_new_communication_page:managerId')}
                                    </Form.Label>
                                    <AsyncTypeahead
                                        filterBy={() => true} // Results are already filter by the backend, no need to filter them again
                                        id="managerId"
                                        isLoading={isLoading}
                                        labelKey={(manager: any) => `${manager.name} ${manager.surname}`}
                                        minLength={3}
                                        onChange={(selectedManagers: any[]) => setFieldValue('managerId', selectedManagers?.[0]?.id ?? '')}
                                        onSearch={handleSearch}
                                        options={managers}
                                        placeholder={t('team_new_communication_page:search_manager_placeholder')}
                                        allowNew={false}
                                        {...(touched.managerId && errors.managerId
                                            ? { isInvalid: true, className: "is-invalid" }
                                            : null)}
                                        renderMenuItemChildren={(foundManager: any) => <p>{foundManager.name} {foundManager.surname}</p>}
                                    />
                                    {touched.managerId && errors.managerId ? (
                                        <div className="invalid-feedback">{t(`yup:${errors.managerId}`)}</div>
                                        ) : null}
                                </Form.Group>
                                <Form.Group controlId="title">
                                    <Form.Label className="col-form-label-sm">
                                        {t('team_new_communication_page:title')}
                                    </Form.Label>
                                    <Form.Control 
                                        type="title"
                                        as="textarea"
                                        style={{'height': '2rem'}}
                                        {...getFieldProps("title")}
                                        isInvalid={!!touched.title && !!errors.title}/>
                                        
                                    <Form.Control.Feedback type="invalid">
                                        {errors.body ? <div>{t(`yup:${errors.body}`)}</div> : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="body">
                                    <Form.Label className="col-form-label-sm">
                                        {t('team_new_communication_page:body')}
                                    </Form.Label>
                                    <Form.Control 
                                        type="body"
                                        as="textarea"
                                        style={{'height': '2rem'}}
                                        {...getFieldProps("body")}
                                        isInvalid={!!touched.body && !!errors.body}/>
                                        
                                    <Form.Control.Feedback type="invalid">
                                        {errors.body ? <div>{t(`yup:${errors.body}`)}</div> : null}
                                    </Form.Control.Feedback>
                                <p></p>
                                </Form.Group>
                                <MultimediaFileChooser 
                                    className='form-control'
                                    style={{'height': 'initial'}}
                                    acceptedFiles={{"image/*": ['.jpeg', '.jpg', '.png'] /*, "application/pdf":['.pdf']*/}}
                                    maxFilesAccepted = {1}
                                    disableDragNDrop = {false}
                                    onFilesDrop={(accFiles: FileWrapper[], rejFiles: FileWrapper[]) => {
                                        log.debug("in onFilesDrop files => ", [accFiles, rejFiles])
                                        if(!accFiles.length) return
                                        values.media ? values.media.push(accFiles[0].file) : values.media = [accFiles[0].file]
                                        setFieldValue('media', values.media)
                                    }}
                                    uploadButtonLabel={t('team_communication_page:buttons.upload')}
                                    >
                                    {!values.media && <FontAwesomeIcon icon={faCloudUpload} size={'6x'}/>}
                                    <div className="display-wrapper wrap-auto flex-wrap">
                                    {values.media && values.media.map((file, index) => {
                                        return (
                                            <FilePreviewViewer 
                                                className={'text-center image-preview'}
                                                file={file} 
                                                key={index}
                                                showDeleteButton={true}
                                                onDelete={(e:any) => {
                                                    values.media?.splice(index, 1)
                                                    setFieldValue('media', values.media?.length ? values.media : undefined)
                                                }}
                                            />
                                    )})}
                                    </div>
                                </MultimediaFileChooser>
                            </Form>
                        )
                    }}
                </Formik>
        </div>
    )
}

export type { NewMessageRequest }

export { TeamNewCommunicationForm }
