import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        //detection: { order: ["navigator"] },
        // we init with resources
        resources: {
            it: {
                user_profile: {
                    profile: "Profilo",
                    change_password: "Cambia Password",
                    logout: "Logout"
                },
                common: {
                    send: "Invia",
                    save: "Salva",
                    cancel: "Annulla",
                    delete: "Elimina",
                    something_went_wrong: "Si è verificato un errore",
                    multimedia_single_drag_n_drop: "Drag 'n' drop del file qui, o click per selezionare un file",
                    multimedia_multiple_drag_n_drop: "Drag 'n' drop dei file qui, o click per selezionare i file",
                    you: "Tu",
                    admin: "Admin",
                    administrator: "Amministratore"
                },
                yup: {
                    invalidEmail: "Indirizzo email non valido",
                    required: "Campo Obbligatorio",
                    manager_required_in_set: "Manager non presente nel sistema",
                    passwordMinLength: "Deve contenere almeno 8 caratteri",
                    placeRequired: "Luogo obbligatorio",
                    startDateRequired: "Data inizio obbligatoria",
                    sharedWithMin: "Devi selezionare almeno 1 persona"
                },
                translations: {
                    page_not_found_title: "Pagina non trovata",
                    page_not_found_descr: "La pagina richiesta non è stata trovata: ",
                    manage_teams: "Gestione Squadre",
                    manage_team: "Gestione Squadra",
                    unlock_teams: "Sblocco Squadre",
                    communication_teams: "Comunicazione Squadre",
                    communication_team: "Comunicazione Squadra",
                    communication_team_new: "Nuova comunicazione",
                    uploads: "Uploads",
                    dashboard: "Dashboard",
                    manage_staff: "Gestione Personale",
                    manage_players: "Gestione Giocatori",
                    contact_admin: "Contatta Admin",
                    communications: "Comunicazioni",
                    calendar: "Calendario",
                    season: "Stagione",
                    trainings: "Allenamenti",
                    team: "Team",
                    playbook: "Playbook",
                    next_game: "Prossima Partita",
                    last_game: "Ultima Partita",
                    coach: "Coach",
                    staff: "Staff"
                },
                login_page: {
                    form: {
                        email_label: "Email",
                        password_label: "Password", 
                    },
                    buttons: {
                        login_btn_label: "Login"
                    }
                },
                team_management_page: {
                    team_list: {
                        league: 'Lega',
                        team: 'Team',
                        team_name: 'Squadra',
                        date: 'Data Attivazione',
                        status: 'Status',
                        no_team_found: 'Nessuna squadra trovata',
                        loading: 'Caricamento in corso...',
                    },
                    team_detail: {
                        league: 'League',
                        date: 'Activation Date',
                        expiration: 'Expiration Date',
                        subscription_type: 'Tipologia Abbonamento',
                        status: 'Status Pagamento',
                        coach: 'Allenatore',
                        birth_date: 'Data di nascita',
                        registered_players: 'Giocatori registrati',
                        player: 'Giocatore',
                        status_player: 'Status',
                        player_active: 'Attivo',
                        player_inactive: 'Inattivo',
                        role: 'Ruolo',
                    }
                },
                team_unlock_page:{
                    request_list:{
                        request: 'Richiesta di sblocco',
                        team: 'Team',
                        date: 'Data richiesta',
                        no_request_found: 'Nessuna richiesta trovata',
                        loading: 'Caricamento in corso...'
                    }
                },
                team_unlock_full_page:{
                    identity_card: "CDI",
                    signed_authorization: "Delega",
                    missing_link: "Mancata Associazione",
                    link: "Associa",
                    motivation:"Motivazione",
                    text_area:"Specifica motivazione",
                    successfully_processed: 'Richiesta di sblocco processata con successo!',
                    dropdown_menu:{
                        field1:"Mancato riconoscimento documento di identità",
                        field2:"Mancato riconoscimento delega",
                        field3:"Mancato riconoscimento di entrambi i documenti",
                        field4:"Altro",
                    }
                },
                team_communication_page: {
                    buttons: {
                        new_communication: 'Crea Nuovo',
                        answer: 'Rispondi',
                        upload: 'Carica jpg, png o pdf'
                    },
                    list: {
                        loading: 'Caricamento lista comunicazioni in corso...',
                        not_found: 'Nessuna comunicazione trovata',
                        team: 'Squadra',
                        sender: 'Mittente',
                        subject: 'Oggetto',
                        date: 'Data',
                    },
                    toast: {
                        success: 'Messaggio inviato con successo'
                    },
                    delete_thread_event: {
                        success: 'Thread cancellati con successo',
                        error: 'Errore nell\'eliminazione dei thread: '
                    }
                },
                team_new_communication_page: {
                    managerId: "Destinatario (Squadra - Team Manager)",
                    search_manager_placeholder: "Cerca un manager...",
                    title: "Oggetto",
                    body: "Testo"
                }
            },
            en: {
                user_profile: {
                    profile: "Profile",
                    change_password: "Change Password",
                    logout: "Logout"
                },
                common: {
                    send: "Send",
                    save: "Save",
                    cancel: "Cancel",
                    delete: "Delete",
                    something_went_wrong: "Something went wrong",
                    multimedia_single_drag_n_drop: "Drag 'n' drop file here, or click to select file",
                    multimedia_multiple_drag_n_drop: "Drag 'n' drop files here, or click to select files",
                    you: "You",
                    admin: "Admin",
                    administrator: "Administrator"
                },
                yup: {
                    invalidEmail: "Invalid email address",
                    required: "Required Field",
                    passwordMinLength: "It must contain at least 8 characters",
                    placeRequired: "Required Place",
                    startDateRequired: "Start date required",
                    sharedWithMin: "Select at least 1 person"
                },
                translations: {
                    manage_teams: "Teams Management",
                    manage_team: "Team Management",
                    unlock_teams: "Teams Unlock",
                    communication_teams: "Teams Communications",
                    communication_team: "Team Communications",
                    communication_team_new: "New Team Communication",
                    uploads: "Uploads",
                    dashboard: "Dashboard",
                    manage_staff: "Staff Management",
                    manage_players: "Players Management",
                    contact_admin: "Contact Admin",
                    communications: "Communications",
                    calendar: "Calendar",
                    season: "Season",
                    trainings: "Trainings",
                    team: "Team",
                    playbook: "Playbook",
                    next_game: "Next Game",
                    last_game: "Last Game",
                    coach: "Coach",
                    staff: "Staff"
                },
                login_page: {
                    form: {
                        email_label: "Email",
                        password_label: "Password", 
                    },
                    buttons: {
                        login_btn_label: "Login"
                    }
                },
                team_management_page: {
                    team_list: {
                        league: 'League',
                        team: 'Logo',
                        team_name: 'Team',
                        date: 'Activation Date',
                        status: 'Status',
                        no_team_found: 'No team found',
                        loading: 'Loading in progress...',
                    },
                    team_detail: {
                        league: 'League',
                        date: 'Activation Date',
                        expiration: 'Expiration Date',
                        subscription_type: 'Subscription Type',
                        status: 'Payment Status',
                        coach: 'Coach',
                        birth_date: 'Date of birth',
                        registered_players: 'Registered Players',
                        player: 'Player',
                        status_player: 'Status',
                        role: 'Role',
                    }
                },
                team_unlock_page:{
                    request_list:{
                        request: 'Unlock request',
                        team: 'Team',
                        date: 'Request Date',
                        no_request_found: 'No request found',
                        loading: 'Loading in progress...',
                    }
                },
                team_unlock_full_page:{
                    identity_card: "IdentityCard",
                    signed_authorization: "SignedAuthorization",
                    missing_link: "Missing Link",
                    link: "Link",
                    motivation:"Motivation",
                    text_area: "Specify motivation",
                    dropdown_menu:{
                        field1:"Failure to recognize the identity document",
                        field2:"failure to recognize the proxy",
                        field3:"failure to recognize both documents",
                        field4:"Other",
                    }
                },
                team_communication_page: {
                    buttons: {
                        new_communication: 'New Message',
                        answer: 'Answer',
                        upload: 'Upload jpg, png or pdf'
                    },
                    list: {
                        loading: 'Loading in progress...',
                        not_found: 'No communication found',
                        team: 'Team',
                        sender: 'Sender',
                        subject: 'Subject',
                        date: 'Date',
                    },
                    toast: {
                        success:"Message sent" 
                    }
                },
                team_new_communication_page: {
                    recipient: "Recipient (Team - Team Manager)" ,
                    object: "Object",
                    body: "Text"
                }
            }
        },
        fallbackLng: 'it',
        debug: process.env.NODE_ENV === 'development',
        ns: ['translations', "playbook_page", "season_page"],
        defaultNS: 'translations',
        interpolation: {
          escapeValue: false,
          formatSeparator: ','
        },
    })

export default i18n