import './LogoPlaceholder.css'
import { faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface NTLProps {
    size?: string,
    url?: string,
}

const TeamLogo = ({size, url}: NTLProps) => {
    if(url && url.length > 0) {
        return <img src={url} className={size === 'big' ? 'big' : 'team-logo'}/>
    }

    if(size && size === 'big')
        return <div className="logo-placeholder big" />
    return <FontAwesomeIcon className='icon-placeholder' color="white" icon={faUserCircle} />
}

export default TeamLogo