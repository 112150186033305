import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import log from "loglevel";
import moment from "moment";
import { ApiClient } from "../../services/ApiClient";
import {AuthProvider} from "../../services/AuthProvider";
import errorParser from "../../services/network/ErrorParser";
import { resetUser, setUser } from "./user.slice";
import { AuthCredentials, AuthTokens, LoginAdmin200Response, RefreshToken } from "../../services/network";
import { throwNewError } from "../../utils/ErrorDispatcher";

export type AuthState = {
    loggedIn: boolean,
    lastLoginTimeStamp: string | undefined,
    lastLogoutTimeStamp?: string | undefined,
    lastLogoutReason?: string,
    promptLogoutAlert?: boolean
}

export const login = createAsyncThunk(
    'login',
    async (body: AuthCredentials, thunkAPI) => {
        log.debug('[Auth.slice] login')
        try{
            const res:AxiosResponse<LoginAdmin200Response> = await ApiClient.authApi().loginAdmin(body)
            log.debug("login response => ", res)
            thunkAPI.dispatch(loginSuccess({...res.data.data ?? throwNewError(res.data.error)}))
            // should we use it?
            // const user = LoginDataToUserMapper(res.data)
            // thunkAPI.dispatch(setUser(user))
        }
        catch(error: any){
            log.error(`login error catch => ${error}`)
            log.error(`login error catch => `, error.response)
            //return thunkAPI.rejectWithValue(errorParser(error))
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const refreshToken = createAsyncThunk(
    'login/refresh',
    async (body:RefreshToken, thunkAPI) => {
        log.debug('[Auth.slice] refresh')
        try {
            const res:AxiosResponse<LoginAdmin200Response> = await ApiClient.authApi().refreshToken(body)
            log.debug("token refresh response => ", res)
            thunkAPI.dispatch(refreshSuccess({...res.data.data ?? throwNewError(res.data.error)}))
        }
        catch(error: any) {
            log.error(`token refresh error catch => ${error}`)
            log.error(`token refresh error catch => `, error.response)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (reason, thunkAPI) => {
        log.debug("in logout, reason => ", reason)
        try {
            //await ApiClient.logout();
            thunkAPI.dispatch(logoutSuccess(reason))
            thunkAPI.dispatch(resetUser({}))
        } catch (error: any) {
            log.error(`logout error catch => ${error}`)
            log.error(`logout error catch => `, error.response)
            return thunkAPI.rejectWithValue(errorParser(error))
        }
    }
)

// export const changePassword = createAsyncThunk(
//     'user/changePassword',
//     async (body, thunkAPI) => {
//         try{
//             let resp = await ApiClient.changePassword(createChangePasswordPayload(body))
//             log.debug("changePassword resp => ", resp)
//             thunkAPI.fulfillWithValue({})
//         } catch(error){
//             log.error(`changePassword error catch => ${error}`)
//             log.error(`changePassword error catch => `, error.response)
//             return thunkAPI.rejectWithValue(errorParser(error))
//         }
//     }
// )

const initialAuthState: AuthState = {
    loggedIn: false, 
    lastLoginTimeStamp: undefined,
    lastLogoutTimeStamp: undefined,
    promptLogoutAlert: false,
    //user: undefined,
    lastLogoutReason: undefined
}

//Slice
const slice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        loginSuccess: (state, action) => {
            log.debug("[reducer] loginSuccess, action => ", action)
            AuthProvider.setTokens(action.payload)
            // state.lastLoginTimeStamp = moment().format()
            // state.loggedIn =  true
            return state = {
                lastLoginTimeStamp: moment().format(),
                loggedIn: true
            }
        },
        refreshSuccess: (state, action) => {
            log.debug("[reducer] refreshSuccess, action => ", action)
            AuthProvider.setTokens(action.payload)
            return state
        },
        logoutSuccess: (state, action) => {
            log.debug("[reducer] logoutSuccess, action => ", action)
            log.debug("[reducer] logoutSuccess, state => ", state)
            AuthProvider.cancelTokens()
            return state = {
                ...initialAuthState, 
                lastLogoutReason: action.payload? action.payload : "user_action",
                lastLogoutTimeStamp: moment().format(),
                promptLogoutAlert: true
            }
        },
        logoutAlertShown: (state, action) => {
            log.debug("[reducer] logoutAlertShown, action => ", action)
            log.debug("[reducer] logoutAlertShown, state => ", state)
            return state = {
                ...state,
                promptLogoutAlert: false
            }
        }
    }
});
export default slice.reducer;

//Actions
export const {
    loginSuccess, 
    refreshSuccess,
    logoutSuccess,
    logoutAlertShown
} = slice.actions