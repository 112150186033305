import { useTranslation } from "react-i18next"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { PendingRegistrationInfoFull } from "../../services/network"
import { ApiClient } from "../../services/ApiClient"
import log from 'loglevel';
import { CardRequestDetail } from "../../components/CardRequestDetail/CardRequestDetail"
import { throwNewError } from "../../utils/ErrorDispatcher"
import { generateToast } from "../../utils/ToastGenerator"

const TeamUnlockDetail = () => {
    
    const navigation = useNavigate()
    const {t} = useTranslation()

    const onBackClick = () => navigation(-1)

    const [loading, setLoading] = useState(true)
    const [requestFull, setRequestFull] = useState<PendingRegistrationInfoFull | undefined>(undefined)

    let { requestId } = useParams();

    const getRequestFull = async (requestId: string) => {
        log.debug('TeamUnlock => requestId', requestId)
        try{
            let res = await ApiClient.adminApi().getPendingRegistration(requestId)
            log.debug('TeamUnlock => getRequestFull =>', res.data)
            setRequestFull(res.data.data ?? throwNewError(res.data.error))
        } catch(e) {
            log.debug('TeamUnlockDetail => getRequestFull => error =>', e)
            generateToast('error', e)
        }
        setLoading(false)
    }

    useEffect(() => {
        requestId && getRequestFull(requestId)
    },[])

    return <>
        <AnimatedPage>
            <Header title={t('team_unlock_page:request_list.request')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                {loading && <h3 className="my-2">{t('team_unlock_page:request_list.loading')}</h3> }
                {!loading && !requestFull && <h3 className="my-2">{t('team_unlock_page:request_list.no_team_found')}</h3>}
                {!loading && requestFull &&  <CardRequestDetail requestFull = {requestFull} />}
            </VerticalScrollableContent>
        </AnimatedPage>
    </>
}

export {TeamUnlockDetail}

