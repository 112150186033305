import log from "loglevel"

export const generateImageFromFile = (file? :File | undefined): string => {
    log.debug("in generateImageFromFile file => ", file)
    if(!file) return ''
    return URL.createObjectURL(file)
}

export const generateVideoFromFile = (file? :File | undefined): string => {
    log.debug("in generateVideoFromFile file => ", file)
    if(!file) return ''
    return URL.createObjectURL(file)
}

export const extractVideoAndGenericAttachmentFiles = (files: File[]): {genericAttachments: File[], videoAttachments: File[]} => {
    const genericAttachments: File[] = []
    const videoAttachments: File[] = []

    files.forEach(file => {
        if(file.type.includes("video")) videoAttachments.push(file)
        else genericAttachments.push(file)
    })

    return {
        genericAttachments,
        videoAttachments
    }
}