import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'

import auth from './reducers/auth.slice'

const reducer = combineReducers({
  auth,
})

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
  })


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch