import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SetStateAction } from "react";
import ReactPaginate from "react-paginate"

interface PaginationBarProps {
    totalPageCount: number,
    currentPageIndex: number,
    setCurrentPageIndex: (value: SetStateAction<number>) => void
}

function PaginationBar({totalPageCount, currentPageIndex, setCurrentPageIndex}: PaginationBarProps) {

    const handlePageClick = (selectedItem: { selected: number }) => {
        setCurrentPageIndex(selectedItem.selected)
    };

    return (
        <ReactPaginate
            previousLabel={
                <FontAwesomeIcon icon={faArrowLeft} />
            }
            nextLabel={
                <FontAwesomeIcon icon={faArrowRight} />
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={currentPageIndex}
            onPageChange={handlePageClick}
            containerClassName={'pagination justify-content-center mt-5 mb-4'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
        />
    )
}
export { PaginationBar }