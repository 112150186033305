import { useEffect, useState } from 'react';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, Location, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { AppDispatch } from '../../redux/reduxStore';
import { logout } from '../../redux/reducers/auth.slice';
import { MenuItem, menuItems as routeMenuItems} from '../../routing/routes';
import './Sidebar.css'
import log from 'loglevel';

const Sidebar = () => {
    const { t } = useTranslation(['translations, user_profile'])
    const location: Location = useLocation()
    const [state, setState] = useState<any>({})
    const reduxDispatch = useDispatch<AppDispatch>()
    const menuItems: MenuItem[] = routeMenuItems

    const toggleMenuState = (menuState: any) => {
        if (state[menuState]) {
          setState({[menuState] : false});
        } else if(Object.keys(state).length === 0) {
          setState({[menuState] : true});
        } else {
          Object.keys(state).forEach(i => {
            setState({[i]: false});
          });
          setState({[menuState] : true}); 
        }
    }

    const logoutUser = () => reduxDispatch(logout())

    const onRouteChanged = () => {
        log.debug("in onRouteChanged")
        document.querySelector('#sidebar')?.classList.remove('active');
        Object.keys(state).forEach(i => {
            setState({[i]: false});
        });

        menuItems.forEach(route => {
            if (isPathActive(route.path)) {
                setState({[route.state? route.state : ''] : true})
            }
        })
    }

    useEffect(()=>{
        log.debug("in useEffect location => ", location)
        onRouteChanged()
    },[location])

    const isPathActive = (path: any) =>  {
        return location.pathname === path;
    }

    log.debug("state => ", state)
    return(
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center">
                    <a className="sidebar-brand brand-logo" href="index.html">
                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                    </a>
                    <a className="sidebar-brand brand-logo-mini" href="index.html">
                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                    </a>
                </div>
                <ul className="nav">
                    <li className="nav-item profile d-none d-lg-block">
                        <div className="profile-desc">
                            <div className="profile-pic">
                                <div className="count-indicator">
                                </div>
                                <div className="profile-name">
                                    <span>
                                        Admin
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-category"/>
                    {menuItems.map((route) => {
                        return <li key={route.path} 
                            className={ isPathActive(route.path) ? 'nav-item menu-items active' : 'nav-item menu-items' }>
                            {
                                
                                <Link className="nav-link" to={route.path}>
                                    <span className="menu-title">
                                        <Trans>{route.i18_label_key}</Trans>
                                    </span>
                                </Link>
                            }
                        </li>
                    })}

                    <li className='nav-item menu-items logout'
                        onClick={logoutUser}
                    >
                        <a className="nav-link">
                            <span className="menu-title">
                                <Trans>Logout</Trans>
                            </span>
                        </a>
                    </li>
                </ul>
            </nav>
    )
}

export default Sidebar