import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Header from "../../components/Header/Header"
import AnimatedPage from "../../components/AnimatedPage/AnimatedPage"
import { TEAM_MANAGEMENT } from "../../routing/routes"
import VerticalScrollableContent from "../../components/VerticalScrollableContent/VerticalScrollableContent"
import { useEffect, useState } from "react"
import log from "loglevel"
import { ApiClient } from "../../services/ApiClient"
import { AdminTeamSummary, PaginationInfo } from "../../services/network"
import { throwNewError } from "../../utils/ErrorDispatcher"
import { generateToast } from "../../utils/ToastGenerator"
import { TeamsTable } from "../../components/TeamsTable/TeamsTable"
import { Row } from "react-bootstrap"
import { SearchBar } from "../../components/SearchBar/SearchBar"
import { PaginationBar } from "../../components/PaginationBar/PaginationBar"

const TeamManagement = () => {

    const navigation = useNavigate()
    const {t} = useTranslation()

    const [teams, setTeams] = useState<AdminTeamSummary[]>([])
    const [loading, setLoading] = useState(true)
    const [queryString, setQueryString] = useState<string | undefined>(undefined)
    const [totalPageCount, setTotalPageCount] = useState<PaginationInfo>({total_pages: 0})
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const onBackClick = () => navigation(-1)
    const navigateToTeam = (teamId: string) => navigation(`${TEAM_MANAGEMENT}/${teamId}`, {replace: false})

    const onQueryUpdate = (queryString: string) => {
        log.debug('in onQueryUpdate queryString => ', queryString)
        setCurrentPageIndex(0)        
        setQueryString(queryString)
    }

    const getTeams = async (queryString?: string, page?: number) => {
        try {
            let res = await ApiClient.adminApi().getTeamsAdmin(queryString, (page ?? 0) + 1)
            log.debug('TeamManagement => getTeams =>', res.data)
            setTeams(res.data.data ?? throwNewError(res.data.error))
            setTotalPageCount(res.data.pagination ?? {total_pages: 1})
        } catch (e) {
            log.debug('TeamManagement => getTeams => error => ', e)
            generateToast('error', e)
        }
        setLoading(false)
    }

    useEffect(() => {
        getTeams(queryString, currentPageIndex)
    }, [queryString, currentPageIndex])
 
    return <>
        <AnimatedPage>
            <Header title={t('manage_teams')} onBackClick={onBackClick}/>
            <VerticalScrollableContent>
                <Row className="text-end mb-4 display-wrapper wrap-center">
                    <SearchBar className={"wrap-auto"} value={queryString ?? ''} onQueryChange={onQueryUpdate}/>
                </Row>
                {loading && <h3 className="my-2"><Trans i18nKey={'team_management_page:team_list.loading'}/></h3>}
                {!loading && (teams.length === 0
                ? <h3 className="my-2"><Trans i18nKey={'team_management_page:team_list.no_team_found'}/></h3>
                : <>
                    <TeamsTable 
                        teams = {teams}
                        onTeamSelected = {navigateToTeam}
                    />
                    <PaginationBar 
                        totalPageCount={totalPageCount.total_pages ?? 1} 
                        currentPageIndex={currentPageIndex} 
                        setCurrentPageIndex={setCurrentPageIndex} 
                        />
                </>
                    )}
                
            </VerticalScrollableContent>
        </AnimatedPage>
    </>
}

export { TeamManagement }