import { Formik } from 'formik';
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { RegistrationResponse } from "./../../services/network"
import log from 'loglevel';



interface TeamUnlockFormProps {
    initialValues: RegistrationResponse,
    validationSchema: any,
    onSubmitCallback: Function,
    isSubmitting: any,
    formRef: any
}
const TeamUnlockForm = ({initialValues, validationSchema, onSubmitCallback, formRef}: TeamUnlockFormProps) => {
    const { t } = useTranslation(['yup'])
    log.debug("initialValues => ", initialValues)
    return(
        
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema} 
            onSubmit={values => {onSubmitCallback(values)}}
            initialValues={initialValues}
            innerRef = {formRef}>
                {formik => {
                    const { handleSubmit, values, touched, isValid, errors, getFieldProps, dirty } = formik
                    return (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Form.Group controlId="reasonDescription">
                                <Form.Control 
                                    type="reasonDescription"
                                    placeholder={t('team_unlock_full_page:text_area')}
                                    as="textarea"
                                    style={{'height': '6rem'}}
                                    {...getFieldProps("reasonDescription")}
                                    isInvalid={!!touched.reasonDescription && !!errors.reasonDescription}/>
                                    
                                <Form.Control.Feedback type="invalid">
                                    {errors.reasonDescription ? <div>{t(`yup:${errors.reasonDescription}`)}</div> : null}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    )
                }}
            </Formik>
        
    )
}


export { TeamUnlockForm }