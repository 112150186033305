import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../../redux/reduxStore";
import log from "loglevel";
import { DASHBOARD_PATH } from "../../routing/routes";

const PrivateRouter = ({child}: {child: JSX.Element}) => {
    const auth = useSelector((state: RootState) => state.auth)
    const location = useLocation()
    log.debug("private route auth => ", auth)
    log.debug("private route location => ", location)
    if(auth.loggedIn) {
        if(location.pathname === '/') {
            return <Navigate to={DASHBOARD_PATH} replace />;
        }

        return child;
    }

    return  <Navigate to="/login" replace />;
}

export default PrivateRouter