import log from "loglevel"

const errorParser = (error: any) => {
    log.debug("in errorParser, error => ", error)
    log.debug("in errorParser, error.response => ", error.response)
    
    const errorCode = error.response?.status
    const errorData = error.response?.data
    
    switch(errorCode){
        case 400: {
            if(errorData){
                switch(errorData.error){
                    case 103: //Database error
                        return "Si è verificato un errore lato server"
                    default:
                        return error.response.data.message
                }
            }
            return "" //should never be the case
        }
        case 401: {
            if(errorData && errorData.error === 403){
                return "La sessione di navigazione è scaduta"
            } else {
                return "La navigazione è scaduta"
            }
        }
        case 404: 
            return "L'API richiesta non è stata trovata"
        default:
            return error.message? error.message : error
    }
}

export default errorParser