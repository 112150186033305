import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC} from "react";

type Props = {
    title: string,
    onBackClick?: Function
}

const Header:FC<Props> = ({title, onBackClick}) => {
    return(
        <div className="mb-4 display-wrapper wrap-row-space-between-center">
            <h2>{title}</h2>
            <FontAwesomeIcon className="cursor-pointer" style={{'fontSize': '2.5rem'}} icon={faArrowLeftLong} onClick={(e)=>{if(onBackClick) onBackClick(e)}}/>
        </div>
    )
}

export default Header