import classNames from "classnames"
import log from "loglevel"
import moment from "moment"
import { FC, HTMLAttributes, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { CarouselItem } from "../Carousel/Carousel"
import { FullScreenFileModal } from "../FilePreviewViewer/FilePreviewViewer"
import { GridItemImage, GridViewContainer } from "../GridViewContainer/GridViewContainer"
import {  ManagerAdminMessage } from "../../services/network"

export interface CardCommunicationDetailProps extends HTMLAttributes<HTMLElement> {
    managerName: string,
    message: ManagerAdminMessage,
}

const CardCommunicationDetail = ({managerName, message}: CardCommunicationDetailProps) => {
    const {t} = useTranslation()
    const [showFullScreenMedia, setShowFullScreenMedia] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const carouselItems: CarouselItem[] = useMemo(()=>{
        log.debug("in useMemo message")
        return message?.attachments?.map(attachment => {return {
            title: attachment.filename ?? 'Name not available',
            src: attachment.url ?? 'URL not available',
            mimeType: attachment.mimeType ?? 'MimeType not available',
            thumbnail: attachment.thumbnailUrl ?? 'Thumbnail not available'
        }}) ?? []
    }, [message])
    const messageMemo = useMemo(()=>{
        log.debug("in useMemo message")
        return message
    }, [message])
    

    return(
        <>
            {
                showFullScreenMedia && FullScreenFileModal(showFullScreenMedia, setShowFullScreenMedia, undefined, undefined, carouselItems, selectedIndex)
            }
            <div key={message.createdAt} className={classNames({'text-end': message.isAdminMessage})}>
                <label className="col-form-label-sm font-weight-bold d-block">
                    {`${message.isAdminMessage ? t('common:admin') : managerName}`}
                </label>
                <div className="form-control d-inline-block w-auto">{message.body}</div>
                    <label className="col-form-label-sm pt-0 d-block">{moment(message.createdAt).format('L HH:mm')}</label>
                <div className="mt-3">
                    <GridViewContainer adminMessage={message.isAdminMessage}>
                        {message.attachments && message.attachments.map((attachment, idx) =>{
                            if(attachment.mimeType?.includes("image")) {
                            return <GridItemImage
                                    callback={()=>{
                                        log.debug("on click griditem image")
                                        setSelectedIndex(idx)
                                        setShowFullScreenMedia(prev =>  !prev)
                                    }}
                                    maxWidth="8rem"
                                    key={attachment.id}
                                    src={attachment.url}
                                /> 
                            }
                        })}
                    </GridViewContainer>
                </div>
            </div>
        </>
    )
}

export { CardCommunicationDetail }