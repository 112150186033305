/* tslint:disable */
/* eslint-disable */
/**
 * Teamkeys API
 * All the Admin APIs of the Teamkeys platform
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetManagerAdminThread200Response } from '../model';
// @ts-ignore
import { GetManagerAdminThreads200Response } from '../model';
// @ts-ignore
import { GetPendingRegistration200Response } from '../model';
// @ts-ignore
import { GetPendingRegistrations200Response } from '../model';
// @ts-ignore
import { GetTeamInfoAdmin200Response } from '../model';
// @ts-ignore
import { GetTeamsAdmin200Response } from '../model';
// @ts-ignore
import { GetTeamsManagers200Response } from '../model';
// @ts-ignore
import { RegistrationResponse } from '../model';
// @ts-ignore
import { SendManagerAdminMessage200Response } from '../model';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete threads
         * @param {Array<number>} threadids IDs of threads to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagerAdminThread: async (threadids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadids' is not null or undefined
            assertParamExists('deleteManagerAdminThread', 'threadids', threadids)
            const localVarPath = `/manager/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (threadids) {
                localVarQueryParameter['threadids'] = threadids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the message in a thread
         * @param {string} threadId ID of thread to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminThread: async (threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getManagerAdminThread', 'threadId', threadId)
            const localVarPath = `/manager/thread/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the conversation threads with the managers
         * @param {string} [queryString] search title threads
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminThreads: async (queryString?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manager/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a certain pending team registration
         * @param {string} notificationId ID of pending registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingRegistration: async (notificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('getPendingRegistration', 'notificationId', notificationId)
            const localVarPath = `/notification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of pending team registration notifications
         * @param {string} [queryString] search notification
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingRegistrations: async (queryString?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the information about a team
         * @param {string} teamID ID of team in the platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInfoAdmin: async (teamID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamID' is not null or undefined
            assertParamExists('getTeamInfoAdmin', 'teamID', teamID)
            const localVarPath = `/team/{teamID}`
                .replace(`{${"teamID"}}`, encodeURIComponent(String(teamID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all the teams in the platform
         * @param {string} [queryString] search team
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsAdmin: async (queryString?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search all team managers
         * @param {string} queryString Name or Surname querystring
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsManagers: async (queryString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryString' is not null or undefined
            assertParamExists('getTeamsManagers', 'queryString', queryString)
            const localVarPath = `/teams/team-manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept or decline a pending registration request
         * @param {string} notificationId ID of pending registration
         * @param {RegistrationResponse} registrationResponse Registration response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPendingRegistration: async (notificationId: string, registrationResponse: RegistrationResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('processPendingRegistration', 'notificationId', notificationId)
            // verify required parameter 'registrationResponse' is not null or undefined
            assertParamExists('processPendingRegistration', 'registrationResponse', registrationResponse)
            const localVarPath = `/notification/{notificationId}/response`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * When sending a message, if it\'s a new message you need to specify the **managerId** (to know which manager is the recipient). If, on the other hand, you are responding to an existing thread, you need to specify the **threadId**
         * @summary Crete a new message to a manager for both an existing and a new thread
         * @param {string} body 
         * @param {string} [managerId] 
         * @param {string} [title] 
         * @param {string} [category] 
         * @param {string} [threadId] 
         * @param {Array<any>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManagerAdminMessage: async (body: string, managerId?: string, title?: string, category?: string, threadId?: string, attachments?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendManagerAdminMessage', 'body', body)
            const localVarPath = `/manager/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (managerId !== undefined) { 
                localVarFormParams.append('managerId', managerId as any);
            }
    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.append('body', body as any);
            }
    
            if (category !== undefined) { 
                localVarFormParams.append('category', category as any);
            }
    
            if (threadId !== undefined) { 
                localVarFormParams.append('threadId', threadId as any);
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete threads
         * @param {Array<number>} threadids IDs of threads to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManagerAdminThread(threadids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManagerAdminThread(threadids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the message in a thread
         * @param {string} threadId ID of thread to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManagerAdminThread(threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManagerAdminThread200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManagerAdminThread(threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the conversation threads with the managers
         * @param {string} [queryString] search title threads
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManagerAdminThreads(queryString?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManagerAdminThreads200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManagerAdminThreads(queryString, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a certain pending team registration
         * @param {string} notificationId ID of pending registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingRegistration(notificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPendingRegistration200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingRegistration(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of pending team registration notifications
         * @param {string} [queryString] search notification
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingRegistrations(queryString?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPendingRegistrations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingRegistrations(queryString, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the information about a team
         * @param {string} teamID ID of team in the platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamInfoAdmin(teamID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamInfoAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamInfoAdmin(teamID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all the teams in the platform
         * @param {string} [queryString] search team
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsAdmin(queryString?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsAdmin(queryString, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search all team managers
         * @param {string} queryString Name or Surname querystring
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsManagers(queryString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamsManagers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsManagers(queryString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept or decline a pending registration request
         * @param {string} notificationId ID of pending registration
         * @param {RegistrationResponse} registrationResponse Registration response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processPendingRegistration(notificationId: string, registrationResponse: RegistrationResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processPendingRegistration(notificationId, registrationResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * When sending a message, if it\'s a new message you need to specify the **managerId** (to know which manager is the recipient). If, on the other hand, you are responding to an existing thread, you need to specify the **threadId**
         * @summary Crete a new message to a manager for both an existing and a new thread
         * @param {string} body 
         * @param {string} [managerId] 
         * @param {string} [title] 
         * @param {string} [category] 
         * @param {string} [threadId] 
         * @param {Array<any>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendManagerAdminMessage(body: string, managerId?: string, title?: string, category?: string, threadId?: string, attachments?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendManagerAdminMessage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendManagerAdminMessage(body, managerId, title, category, threadId, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete threads
         * @param {Array<number>} threadids IDs of threads to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagerAdminThread(threadids: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManagerAdminThread(threadids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the message in a thread
         * @param {string} threadId ID of thread to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminThread(threadId: string, options?: any): AxiosPromise<GetManagerAdminThread200Response> {
            return localVarFp.getManagerAdminThread(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the conversation threads with the managers
         * @param {string} [queryString] search title threads
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagerAdminThreads(queryString?: string, page?: number, options?: any): AxiosPromise<GetManagerAdminThreads200Response> {
            return localVarFp.getManagerAdminThreads(queryString, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a certain pending team registration
         * @param {string} notificationId ID of pending registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingRegistration(notificationId: string, options?: any): AxiosPromise<GetPendingRegistration200Response> {
            return localVarFp.getPendingRegistration(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of pending team registration notifications
         * @param {string} [queryString] search notification
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingRegistrations(queryString?: string, page?: number, options?: any): AxiosPromise<GetPendingRegistrations200Response> {
            return localVarFp.getPendingRegistrations(queryString, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the information about a team
         * @param {string} teamID ID of team in the platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInfoAdmin(teamID: string, options?: any): AxiosPromise<GetTeamInfoAdmin200Response> {
            return localVarFp.getTeamInfoAdmin(teamID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all the teams in the platform
         * @param {string} [queryString] search team
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsAdmin(queryString?: string, page?: number, options?: any): AxiosPromise<GetTeamsAdmin200Response> {
            return localVarFp.getTeamsAdmin(queryString, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search all team managers
         * @param {string} queryString Name or Surname querystring
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsManagers(queryString: string, options?: any): AxiosPromise<GetTeamsManagers200Response> {
            return localVarFp.getTeamsManagers(queryString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept or decline a pending registration request
         * @param {string} notificationId ID of pending registration
         * @param {RegistrationResponse} registrationResponse Registration response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPendingRegistration(notificationId: string, registrationResponse: RegistrationResponse, options?: any): AxiosPromise<void> {
            return localVarFp.processPendingRegistration(notificationId, registrationResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * When sending a message, if it\'s a new message you need to specify the **managerId** (to know which manager is the recipient). If, on the other hand, you are responding to an existing thread, you need to specify the **threadId**
         * @summary Crete a new message to a manager for both an existing and a new thread
         * @param {string} body 
         * @param {string} [managerId] 
         * @param {string} [title] 
         * @param {string} [category] 
         * @param {string} [threadId] 
         * @param {Array<any>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManagerAdminMessage(body: string, managerId?: string, title?: string, category?: string, threadId?: string, attachments?: Array<any>, options?: any): AxiosPromise<SendManagerAdminMessage200Response> {
            return localVarFp.sendManagerAdminMessage(body, managerId, title, category, threadId, attachments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - interface
 * @export
 * @interface AdminApi
 */
export interface AdminApiInterface {
    /**
     * 
     * @summary Delete threads
     * @param {Array<number>} threadids IDs of threads to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    deleteManagerAdminThread(threadids: Array<number>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get all the message in a thread
     * @param {string} threadId ID of thread to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getManagerAdminThread(threadId: string, options?: AxiosRequestConfig): AxiosPromise<GetManagerAdminThread200Response>;

    /**
     * 
     * @summary Get all the conversation threads with the managers
     * @param {string} [queryString] search title threads
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getManagerAdminThreads(queryString?: string, page?: number, options?: AxiosRequestConfig): AxiosPromise<GetManagerAdminThreads200Response>;

    /**
     * 
     * @summary Get a certain pending team registration
     * @param {string} notificationId ID of pending registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getPendingRegistration(notificationId: string, options?: AxiosRequestConfig): AxiosPromise<GetPendingRegistration200Response>;

    /**
     * 
     * @summary Get a list of pending team registration notifications
     * @param {string} [queryString] search notification
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getPendingRegistrations(queryString?: string, page?: number, options?: AxiosRequestConfig): AxiosPromise<GetPendingRegistrations200Response>;

    /**
     * 
     * @summary Get all the information about a team
     * @param {string} teamID ID of team in the platform
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getTeamInfoAdmin(teamID: string, options?: AxiosRequestConfig): AxiosPromise<GetTeamInfoAdmin200Response>;

    /**
     * 
     * @summary Get a list of all the teams in the platform
     * @param {string} [queryString] search team
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getTeamsAdmin(queryString?: string, page?: number, options?: AxiosRequestConfig): AxiosPromise<GetTeamsAdmin200Response>;

    /**
     * 
     * @summary Search all team managers
     * @param {string} queryString Name or Surname querystring
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getTeamsManagers(queryString: string, options?: AxiosRequestConfig): AxiosPromise<GetTeamsManagers200Response>;

    /**
     * 
     * @summary Accept or decline a pending registration request
     * @param {string} notificationId ID of pending registration
     * @param {RegistrationResponse} registrationResponse Registration response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    processPendingRegistration(notificationId: string, registrationResponse: RegistrationResponse, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * When sending a message, if it\'s a new message you need to specify the **managerId** (to know which manager is the recipient). If, on the other hand, you are responding to an existing thread, you need to specify the **threadId**
     * @summary Crete a new message to a manager for both an existing and a new thread
     * @param {string} body 
     * @param {string} [managerId] 
     * @param {string} [title] 
     * @param {string} [category] 
     * @param {string} [threadId] 
     * @param {Array<any>} [attachments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    sendManagerAdminMessage(body: string, managerId?: string, title?: string, category?: string, threadId?: string, attachments?: Array<any>, options?: AxiosRequestConfig): AxiosPromise<SendManagerAdminMessage200Response>;

}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI implements AdminApiInterface {
    /**
     * 
     * @summary Delete threads
     * @param {Array<number>} threadids IDs of threads to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteManagerAdminThread(threadids: Array<number>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteManagerAdminThread(threadids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the message in a thread
     * @param {string} threadId ID of thread to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getManagerAdminThread(threadId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getManagerAdminThread(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the conversation threads with the managers
     * @param {string} [queryString] search title threads
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getManagerAdminThreads(queryString?: string, page?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getManagerAdminThreads(queryString, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a certain pending team registration
     * @param {string} notificationId ID of pending registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getPendingRegistration(notificationId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getPendingRegistration(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of pending team registration notifications
     * @param {string} [queryString] search notification
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getPendingRegistrations(queryString?: string, page?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getPendingRegistrations(queryString, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the information about a team
     * @param {string} teamID ID of team in the platform
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getTeamInfoAdmin(teamID: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getTeamInfoAdmin(teamID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all the teams in the platform
     * @param {string} [queryString] search team
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getTeamsAdmin(queryString?: string, page?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getTeamsAdmin(queryString, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search all team managers
     * @param {string} queryString Name or Surname querystring
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getTeamsManagers(queryString: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getTeamsManagers(queryString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept or decline a pending registration request
     * @param {string} notificationId ID of pending registration
     * @param {RegistrationResponse} registrationResponse Registration response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public processPendingRegistration(notificationId: string, registrationResponse: RegistrationResponse, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).processPendingRegistration(notificationId, registrationResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * When sending a message, if it\'s a new message you need to specify the **managerId** (to know which manager is the recipient). If, on the other hand, you are responding to an existing thread, you need to specify the **threadId**
     * @summary Crete a new message to a manager for both an existing and a new thread
     * @param {string} body 
     * @param {string} [managerId] 
     * @param {string} [title] 
     * @param {string} [category] 
     * @param {string} [threadId] 
     * @param {Array<any>} [attachments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public sendManagerAdminMessage(body: string, managerId?: string, title?: string, category?: string, threadId?: string, attachments?: Array<any>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).sendManagerAdminMessage(body, managerId, title, category, threadId, attachments, options).then((request) => request(this.axios, this.basePath));
    }
}
